import React, { useEffect, useState } from 'react';
import CSVReaderComponent from '../../CSVParser/CSVParser';
import moment from 'moment';

import {
  ImportInstrumentEquipments,
  setInstrumentEquipments,
  setInstrumentEvents,
  useGetPersonnel,
  useInstrumentSettings
} from '../../../util/db';
import { SETTING_TYPE } from './DefineCategories';
import ModalError from '../../Common/modal/ModalError';
import { dateFormat, setCurrentTime } from '../../../util/util';
import { Alert } from 'react-bootstrap';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';
import { useAuth } from '../../../util/auth';

const ImportInstruments = () => {
  const auth = useAuth();
  const { data: personnel } = useGetPersonnel();
  const { data: instrumentSettingsEventType = [] } = useInstrumentSettings(SETTING_TYPE.EVENT_TYPE);
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentSettingsInstrumentStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );

  const { data: instrumentSettingsInstrumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_TYPE
  );
  const { data: instrumentSettingsInstrumentParameter = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_PARAMETER
  );

  const { data: instrumentSettingsInstrumentLocation = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_LOCATION
  );

  const { data: instrumentSettingsManufacturer = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_MANUFACTURER
  );

  const [showError, setShowError] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  const readCSVData = (results) => {
    return results
      .map((instrument) => {
        if (
          instrument[getFieldIndex('Location')] &&
          instrument[getFieldIndex('Location')] !== 'Location'
        ) {
          return {
            location: instrument[getFieldIndex('Location')],
            name: instrument[getFieldIndex('Instrument Name')],
            parameter: instrument[getFieldIndex('Parameter')],
            serialNumber: instrument[getFieldIndex('Serial Number')],
            manufacturer: instrument[getFieldIndex('Manufacturer')],
            model: instrument[getFieldIndex('Model')],
            notes: instrument[getFieldIndex('Notes')],
            internalId: instrument[getFieldIndex('Internal ID')],
            status: instrument[getFieldIndex('Status')],
            type: instrument[getFieldIndex('Type')],
            responsibleParty: instrument[getFieldIndex('Responsible Party')],
            cost: instrument[getFieldIndex('Cost')],
            inServiceDate: instrument[getFieldIndex('In Service Date')],
            nickname: instrument[getFieldIndex('Nickname')],
            highPriorityInstrument: instrument[getFieldIndex('Instrument Priority')],
            connectedInstrument: instrument[getFieldIndex('Connected Instrument')],
            expectedLifeYears: instrument[getFieldIndex('Expected Life (Years)')],
            events: [
              {
                instrumentName: instrument[getFieldIndex('Instrument Name')],
                eventType: instrument[getFieldIndex('Event Type 1')],
                description: instrument[getFieldIndex('Description 1')],
                responsibleParty: instrument[getFieldIndex('Responsible Party 1')],
                status: instrument[getFieldIndex('Status 1')],
                dateDue: instrument[getFieldIndex('Date Due 1')],
                cost: instrument[getFieldIndex('Cost 1')],
                notificationLeadTime: instrument[getFieldIndex('Notification Lead Time (days) 1')],
                recurringEvent: instrument[getFieldIndex('Recurring Event 1')],
                recurringInterval: instrument[getFieldIndex('Recurring Interval (days) 1')],
                notes: instrument[getFieldIndex('Notes 1')]
              },
              {
                instrumentName: instrument[getFieldIndex('Instrument Name')],
                eventType: instrument[getFieldIndex('Event Type 2')],
                description: instrument[getFieldIndex('Description 2')],
                responsibleParty: instrument[getFieldIndex('Responsible Party 2')],
                status: instrument[getFieldIndex('Status 2')],
                dateDue: instrument[getFieldIndex('Date Due 2')],
                cost: instrument[getFieldIndex('Cost 2')],
                notificationLeadTime: instrument[getFieldIndex('Notification Lead Time (days) 2')],
                recurringEvent: instrument[getFieldIndex('Recurring Event 2')],
                recurringInterval: instrument[getFieldIndex('Recurring Interval (days) 2')],
                notes: instrument[getFieldIndex('Notes 2')]
              }
            ]
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const getFieldIndex = (title) => {
    const csvHeader = [
      'Location',
      'Instrument Name',
      'Serial Number',
      'Manufacturer',
      'Model',
      'Notes',
      'Internal ID',
      'Status',
      'Cost',
      'Type',
      'Parameter',
      'Responsible Party',
      'In Service Date',
      'Nickname',
      'Instrument Priority',
      'Connected Instrument',
      'Expected Life (Years)',
      'Event Type 1',
      'Description 1',
      'Responsible Party 1',
      'Status 1',
      'Date Due 1',
      'Cost 1',
      'Notification Lead Time (days) 1',
      'Recurring Event 1',
      'Recurring Interval (days) 1',
      'Notes 1',
      'Event Type 2',
      'Description 2',
      'Responsible Party 2',
      'Status 2',
      'Date Due 2',
      'Cost 2',
      'Notification Lead Time (days) 2',
      'Recurring Event 2',
      'Recurring Interval (days) 2',
      'Notes 2'
    ];

    return csvHeader.indexOf(title);
  };
  const handleResults = async (results) => {
    setLoading(true);
    const instruments = readCSVData(results);

    const errorMessages = instruments.flatMap((instrument) => validateData(instrument));

    if (errorMessages.length !== 0) {
      setErrorData({
        messages: errorMessages,
        textButton: 'Ok'
      });
      setShowError(true);
      setLoading(false);
      return;
    }

    const instrumentsData = instruments.map((instrument) => mapInstrumentData(instrument));

    await ImportInstrumentEquipments(instrumentsData);

    setShow(true);
    setLoading(false);
  };

  const mapInstrumentData = (instrument) => {
    const statusIndex = instrumentSettingsInstrumentStatus.findIndex(
      (s) => s.name === instrument.status
    );

    const typeIndex = instrumentSettingsInstrumentType.findIndex((s) => s.name === instrument.type);

    const parameterIndex = instrumentSettingsInstrumentParameter.findIndex(
      (s) => s.name === instrument.parameter
    );

    const locationIndex = instrumentSettingsInstrumentLocation.findIndex(
      (s) => s.name === instrument.location
    );

    const manufacturerIndex = instrumentSettingsManufacturer.findIndex(
      (s) => s.name === instrument.manufacturer
    );

    const responsiblePartyIndex = personnel.findIndex((s) => s.name == instrument.responsibleParty);

    return {
      ...instrument,
      status: instrumentSettingsInstrumentStatus[statusIndex].id,
      type: instrumentSettingsInstrumentType[typeIndex].id,
      parameter: instrumentSettingsInstrumentParameter[parameterIndex].id,
      responsibleParty: personnel[responsiblePartyIndex].id,
      location: instrumentSettingsInstrumentLocation[locationIndex].id,
      manufacturer: instrumentSettingsManufacturer[manufacturerIndex].id,
      connectedInstrument: instrument.connectedInstrument === 'Yes' ? true : false,
      inServiceDate: instrument.inServiceDate
        ? setCurrentTime(
            moment(instrument.inServiceDate, dateFormat(instrument.inServiceDate))
          ).toDate()
        : null,
      events: instrument.events?.map((event) => mapEventData(event)).filter(Boolean)
    };
  };

  const mapEventData = (event) => {
    if (!(event.status && event.responsibleParty && event.eventType && event.dateDue)) {
      return;
    }
    const statusIndex = instrumentSettingsEventStatus.findIndex((s) => s.name == event.status);
    const responsiblePartyIndex = personnel.findIndex((s) => s.name == event.responsibleParty);
    const eventTypeIndex = instrumentSettingsEventType.findIndex((s) => s.name == event.eventType);
    const recurringInterval =
      event?.recurringEvent === 'Yes' ? `${event.recurringInterval}|day` : '';

    return {
      ...event,
      recurringInterval,
      status: instrumentSettingsEventStatus[statusIndex].id,
      responsibleParty: personnel[responsiblePartyIndex].id,
      eventType: instrumentSettingsEventType[eventTypeIndex].id,
      dateDue: setCurrentTime(moment(event.dateDue, dateFormat(event.dateDue))).toDate()
    };
  };

  const validateData = (instrument) => {
    const { events, ...instrumentField } = instrument;

    const instrumentErrorMessages = isValidInstrument(instrumentField);

    const eventErrorMessages = events.flatMap((event) => isValidEvent(event));

    if (instrumentErrorMessages.length > 0 || eventErrorMessages.length > 0) {
      const errorMessages = instrumentErrorMessages.concat(eventErrorMessages);

      errorMessages.unshift(`-----------> ${instrument.location} - ${instrument.name}`);

      return errorMessages;
    }

    return [];
  };

  const invalidEnumError = (fieldName, fieldValue) => `${fieldName} "${fieldValue}" invalid`;

  const mustHaveValueError = (fieldName) => `${fieldName} is must have value`;

  const mustBeNumberError = (fieldName, fieldValue) =>
    `${fieldName} "${fieldValue}" must be number`;

  const mustBeDateError = (fieldName, fieldValue) => `${fieldName} "${fieldValue}" must be date`;

  const validateField = (
    fieldName,
    fieldValue,
    isRequired = false,
    isNumber = false,
    isDate = false,
    isInList = false,
    listData = []
  ) => {
    const errorMessages = [];

    if (isRequired && !fieldValue) {
      errorMessages.push(mustHaveValueError(fieldName));
      return errorMessages;
    }

    if (fieldValue && isNumber && isNaN(fieldValue)) {
      errorMessages.push(mustBeNumberError(fieldName, fieldValue));
    }

    if (fieldValue && isDate && !moment(fieldValue, dateFormat(fieldValue), true).isValid()) {
      errorMessages.push(mustBeDateError(fieldName, fieldValue));
    }

    if (fieldValue && isInList && !listData.includes(fieldValue)) {
      errorMessages.push(invalidEnumError(fieldName, fieldValue));
    }

    return errorMessages;
  };

  const isValidInstrument = (instrument) => {
    return [
      ...validateField(
        'Location',
        instrument.location,
        true,
        false,
        false,
        true,
        instrumentSettingsInstrumentLocation.map((s) => s.name)
      ),
      ...validateField('Name', instrument.name, true),
      ...validateField(
        'Status',
        instrument.status,
        true,
        false,
        false,
        true,
        instrumentSettingsInstrumentStatus.map((s) => s.name)
      ),
      ...validateField(
        'Type',
        instrument.type,
        true,
        false,
        false,
        true,
        instrumentSettingsInstrumentType.map((s) => s.name)
      ),
      ...validateField(
        'Parameter',
        instrument.parameter,
        true,
        false,
        false,
        true,
        instrumentSettingsInstrumentParameter.map((s) => s.name)
      ),
      ...validateField(
        'Responsible Party',
        instrument.responsibleParty,
        true,
        false,
        false,
        true,
        personnel.map((p) => p.name)
      ),
      ...validateField('Model', instrument.model, true),
      ...validateField('Serial Number', instrument.serialNumber, true),
      ...validateField(
        'Manufacturer',
        instrument.manufacturer,
        true,
        false,
        false,
        true,
        instrumentSettingsManufacturer.map((s) => s.name)
      ),
      ...validateField('Internal ID', instrument.internalId, true),
      ...validateField('Cost', instrument.cost, false, true),
      ...validateField('In Service Date', instrument.inServiceDate, false, false, true),
      ...validateField(
        'Instrument Priority',
        instrument.highPriorityInstrument,
        false,
        false,
        false,
        true,
        ['1', '2', '3', '4', '5']
      ),
      ...validateField(
        'Connected Instrument',
        instrument.connectedInstrument,
        false,
        false,
        false,
        true,
        ['Yes', 'No']
      ),
      ...validateField('Expected Life (Years)', instrument.expectedLifeYears, false, true)
    ];
  };

  const isValidEvent = (event) => {
    //  Case Empty Event
    if (
      event.cost === '' &&
      event.description === '' &&
      event.dateDue === '' &&
      event.eventType === '' &&
      event.notes === '' &&
      event.notificationLeadTime === '' &&
      event.recurringEvent === '' &&
      event.recurringInterval === '' &&
      event.responsibleParty === '' &&
      event.status === ''
    ) {
      return [];
    }
    return [
      ...validateField('Instrument Name', event.instrumentName, true),
      ...validateField(
        'Event Type',
        event.eventType,
        true,
        false,
        false,
        true,
        instrumentSettingsEventType.map((ev) => ev.name)
      ),
      ...validateField('Description', event.description, true),
      ...validateField(
        'Responsible Party',
        event.responsibleParty,
        true,
        false,
        false,
        true,
        personnel.map((p) => p.name)
      ),
      ...validateField(
        'Status',
        event.status,
        true,
        false,
        false,
        true,
        instrumentSettingsEventStatus.map((s) => s.name)
      ),
      ...validateField('Date Due', event.dateDue, true, false, true),
      ...validateField('Cost', event.cost, false, true),
      ...validateField('Notification Lead Time', event.notificationLeadTime, false, true),
      ...validateField('Recurring Event', event.recurringEvent, true, false, false, true, [
        'Yes',
        'No'
      ]),
      ...(event.recurringEvent === 'Yes'
        ? validateField('Recurring Interval', event.recurringInterval, true, true)
        : [])
    ];
  };

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.CREATE) ? (
        <>
          <ModalError
            show={showError}
            setShow={setShowError}
            title="Import Instrument Error"
            messages={errorData.messages}
            textButton={errorData.textButton}
          />
          <Alert
            key="success"
            show={show}
            dismissible
            onClose={() => setShow(false)}
            variant="success"
          >
            Successfully imported instruments
          </Alert>
          <CSVReaderComponent
            config={{ encoding: 'ISO-8859-1' }}
            handleResults={handleResults}
            loading={loading}
          />
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default ImportInstruments;
