import React, { useEffect, useState } from 'react';
import './MethodSection.scss';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../util/auth';
import {
  createMethod,
  deleteMethod,
  updateMethod,
  useGetMethods,
  useGetPersonnel
} from '../../util/db';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import CustomTable from '../Table/CustomTable';
import { SCREEN, RULES } from '../../util/Constant';
import {
  checkDelete,
  checkUpdate,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission
} from '../../util/util';

function MethodSection({ variation = '' }) {
  const { data: personnel } = useGetPersonnel(variation);
  const [currentMethods, setCurrentMethods] = useState([]);
  const [methodsToUpdate, setMethodsToUpdate] = useState([]);
  const { data: methods } = useGetMethods(variation);
  const [showModal, setShowModal] = useState(false);
  const [permission, setPermission] = useState(null);
  const auth = useAuth();
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const defaultNewMethod = {
    method: '',
    version: '',
    name: '',
    shorthand: '',
    parameter: '',
    variant: '',
    procedure: '',
    units: '',
    reproducibility: '',
    repeatability: '',
    notes: '',
    reportingResolution: ''
  };
  const [newMethod, setNewMethod] = useState(defaultNewMethod);
  const [actions, setActions] = useState(['none']);
  const [hasEdit, setHasEdit] = useState(false);

  useEffect(() => {
    if (permission) {
      const value = [];
      if (checkDelete(permission, SCREEN.METHOD)) {
        value.push('delete');
      }
      if (checkUpdate(permission, SCREEN.METHOD)) {
        setHasEdit(checkUpdate(permission, SCREEN.METHOD));
        value.push('edit');
      }
      setActions(value);
    }
  }, [permission]);

  const handleCreateMethod = () => {
    createMethod(newMethod, variation);
    setNewMethod(defaultNewMethod);
    setShowModal(false);
  };

  useEffect(() => {
    if (Array.isArray(methods) && methods.length > 0) {
      setCurrentMethods(methods);
    }
  }, [methods]);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
      setCurrentPersonnel(personnel);
    }
  }, [personnel]);

  const handleMethodChange = (propertyName, value) => {
    setNewMethod({
      ...newMethod,
      [propertyName]: value
    });
  };

  const saveMethods = () => {
    const currentMethodsToUpdate = methodsToUpdate;
    currentMethodsToUpdate.forEach((method) => {
      updateMethod(method.id, method, variation);
    });
    setMethodsToUpdate([]);
  };

  const methodTableColumns = [
    {
      field: 'methodAlias',
      headerName: 'Alias',
      editable: hasEdit,
      flex: 2,
      valueGetter: (params) => {
        if (params.value) {
          return params.value;
        }
        return '';
      }
    },
    {
      field: 'method',
      headerName: 'Method',
      editable: hasEdit,
      flex: 2
    },
    {
      field: 'version',
      headerName: 'Version',
      editable: hasEdit,
      flex: 3
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: hasEdit,
      flex: 10
    },
    {
      field: 'shorthand',
      headerName: 'Shorthand',
      editable: hasEdit,
      flex: 3
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      editable: hasEdit,
      flex: 3
    },
    {
      field: 'variant',
      headerName: 'Variant',
      editable: hasEdit,
      flex: 2
    },
    {
      field: 'procedure',
      headerName: 'Procedure',
      editable: hasEdit,
      flex: 3
    },
    {
      field: 'units',
      headerName: 'Units',
      editable: hasEdit,
      flex: 2
    },
    {
      field: 'reproducibility',
      headerName: 'Reproducibility',
      editable: hasEdit,
      flex: 4
    },
    {
      field: 'repeatability',
      headerName: 'Repeatability',
      editable: hasEdit,
      flex: 4
    },
    {
      field: 'notes',
      headerName: 'Notes',
      editable: hasEdit,
      flex: 6
    },
    {
      field: 'reportingResolution',
      headerName: 'Reporting Resolution',
      editable: hasEdit,
      flex: 3
    }
  ];

  const handleResults = (results) => {
    results.forEach((method, index) => {
      if (index > 0) {
        const methodToAdd = {
          method: method[0],
          version: method[1],
          name: method[2],
          shorthand: method[3],
          parameter: method[4],
          variant: method[5],
          procedure: method[6],
          units: method[7],
          reproducibility: method[8],
          repeatability: method[9],
          notes: method[10],
          reportingResolution: method[11] || ''
        };
        createMethod(methodToAdd, variation);
      }
    });
  };

  return (
    <div>
      <div className="header">
        <h2>Method and Parameter Information</h2>
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="personnel-information-container">
        <CustomTable
          numberOfRows={10}
          data={currentMethods}
          header={methodTableColumns}
          action={actions}
          rowHeight={'auto'}
          updateRowData={(data) => {
            setMethodsToUpdate([...methodsToUpdate, data]);
          }}
          deleteRowData={(data) => {
            deleteMethod(data.id, variation);
          }}
        />
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Method',
              () => setShowModal(true),
              SCREEN.METHOD,
              RULES.CREATE,
              permission
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permission,
            SCREEN.METHOD,
            RULES.CREATE
          )}
        </div>
        <div>
          {renderButtonWithPermissions(
            'Save Methods',
            () => saveMethods(),
            SCREEN.METHOD,
            RULES.SAVE,
            permission
          )}
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Method</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.method}
                onChange={(e) => handleMethodChange('method', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Version</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.version}
                onChange={(e) => handleMethodChange('version', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.name}
                onChange={(e) => handleMethodChange('name', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Shorthand</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.shorthand}
                onChange={(e) => handleMethodChange('shorthand', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label>Parameter</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.parameter}
                onChange={(e) => handleMethodChange('parameter', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
              <Form.Label>Variant</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.variant}
                onChange={(e) => handleMethodChange('variant', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
              <Form.Label>Procedure</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.procedure}
                onChange={(e) => handleMethodChange('procedure', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput8">
              <Form.Label>Units</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.units}
                onChange={(e) => handleMethodChange('units', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput9">
              <Form.Label>Reproducibility</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.reproducibility}
                onChange={(e) => handleMethodChange('reproducibility', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
              <Form.Label>Repeatability</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.repeatability}
                onChange={(e) => handleMethodChange('repeatability', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput11">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.notes}
                onChange={(e) => handleMethodChange('notes', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput12">
              <Form.Label>Reporting Resolution</Form.Label>
              <Form.Control
                type="text"
                value={newMethod.reportingResolution}
                onChange={(e) => handleMethodChange('reportingResolution', e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowModal(false)} />
          <PrimaryButton text="Save" clicked={() => handleCreateMethod()} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MethodSection;
