import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import './TypeSection.scss';
import Modal from 'react-bootstrap/Modal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../util/firebase';
import { useGetIndexValues, useGetTypes, useGetPersonnel, useGetSetupGroups } from '../../util/db';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { useAuth } from '../../util/auth';
import CustomTable from '../Table/CustomTable';
import { RULES, SCREEN } from '../../util/Constant';
import {
  checkDelete,
  checkUpdate,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission
} from '../../util/util';
import moment from 'moment';
import { useCSVDownloader } from 'react-papaparse';

const functions = getFunctions(firebaseApp);
const updateSetupGroupsGenericFunction = httpsCallable(functions, 'updateSetupGroupsGeneric');

function TypeSection({ variation = '' }) {
  const auth = useAuth();

  const [currentTypes, setCurrentTypes] = useState([]);
  const { data: types } = useGetTypes(variation);
  const [typesToUpdate, setTypesToUpdate] = useState([]);
  const { data: personnel } = useGetPersonnel(variation);
  const [currentDepartments, setCurrentDepartments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [indexes, setIndexes] = useState([]);
  const [currentIndexes, setCurrentIndexes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const [permission, setPermission] = useState(null);
  const { data: setupGroups } = useGetSetupGroups(variation);
  const defaultType = {
    location: '',
    name: '',
    notes: '',
    recType: 'types'
  };
  const [currentModalType, setCurrentModalType] = useState(defaultType);
  const [actions, setActions] = useState(['none']);
  const [hasEdit, setHasEdit] = useState(false);

  const [csvData, setCSvData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    if (permission) {
      const value = [];
      if (checkDelete(permission, SCREEN.TYPE)) {
        value.push('delete');
      }
      if (checkUpdate(permission, SCREEN.TYPE)) {
        setHasEdit(checkUpdate(permission, SCREEN.TYPE));
        value.push('edit');
      }
      setActions(value);
    }
  }, [permission]);

  useEffect(() => {
    if (Array.isArray(setupGroups) && Array.isArray(types) && auth) {
      const setupGroupsAssigned = [];
      const typesToReturn = [];
      setupGroups.forEach((setupGroup) => {
        const matchingUser = setupGroup.personnel.find(
          (person) => person.email === auth.user.email
        );
        if (matchingUser) {
          setupGroupsAssigned.push(setupGroup);
        }
      });
      setupGroupsAssigned.forEach((assignedSetupGroup) => {
        if (Array.isArray(assignedSetupGroup.types)) {
          assignedSetupGroup.types.forEach((typeAssigned) => {
            const existingType = types.find((type) => type.id === typeAssigned);
            if (existingType) {
              typesToReturn.push(existingType);
            }
          });
        }
      });
      setCurrentTypes(typesToReturn);
    }
  }, [setupGroups, types, auth]);

  const saveTypes = () => {
    const currentTypesToUpdate = typesToUpdate;
    currentTypesToUpdate.forEach((type) => {
      updateSetupGroupsGenericFunction({
        updateType: 'update',
        name: 'type',
        theObject: type,
        variation
      });
    });
    setTypesToUpdate([]);
  };

  useEffect(() => {
    const updatedLocationsPersonnel = [];
    currentIndexes.forEach((index) => {
      const locationToPush = {
        id: index.name,
        name: index.name,
        value: index.name,
        label: index.name
      };
      updatedLocationsPersonnel.push(locationToPush);
    });
    setAllLocations(updatedLocationsPersonnel);
    console.log('allLocations', allLocations);
  }, [currentIndexes]);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
      setCurrentPersonnel(personnel);
    }
  }, [personnel]);

  useEffect(() => {
    const csvHeader = ['Location', 'Type Name', 'Notes 1'];

    const columns = ['location', 'name', 'notes'];

    const data = currentTypes.map((row) => {
      const object = csvHeader.map((c, index) => {
        const fieldName = columns[index] || undefined;

        if (fieldName === undefined) {
          return [c, ''];
        }

        return [c, row[fieldName]];
      });

      const entries = new Map([...object]);
      return Object.fromEntries(entries);
    });

    setCSvData(data);
  }, [currentTypes]);

  const renderLocationOptions = (dropdownOptions) => {
    const rows = [];
    if (!Array.isArray(dropdownOptions) || dropdownOptions.length === 0) {
      console.log('No locations found');
      return [];
    } else {
      dropdownOptions.forEach((option) => {
        rows.push(
          <option key={uuidv4()} value={option.name}>
            {option.name}
          </option>
        );
      });
      return rows;
    }
  };

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0) {
      const activeIndexes = indexes.filter((index) => !index.id.includes('Archive'));
      if (activeIndexes.length > 0) {
        setCurrentIndexes(activeIndexes[0].index);
      }
    }
  }, [indexes]);

  useEffect(() => {
    (async () => {
      const result = await useGetIndexValues(variation);
      setIndexes(result.data);
    })();
  }, []);

  const dataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      editable: hasEdit,
      flex: 4,
      sortable: false,
      type: 'singleSelect',
      valueOptions: allLocations
    },
    {
      field: 'name',
      headerName: 'Type Name',
      editable: hasEdit,
      flex: 3,
      sortable: true
    },
    {
      field: 'notes',
      headerName: 'Notes',
      editable: hasEdit,
      flex: 2,
      sortable: false
    }
  ];

  const handleResults = (results) => {
    updateSetupGroupsGenericFunction({
      updateType: 'addList',
      name: 'type',
      variation,
      theObject: results
        .map((type) => {
          if (type[0] && type[0] !== 'Location') {
            return {
              location: type[0],
              name: type[1],
              notes: type[2],
              recType: 'types'
            };
          } else {
            return null;
          }
        })
        .filter(Boolean)
    });
  };

  const handleTypeAdd = () => {
    updateSetupGroupsGenericFunction({
      updateType: 'add',
      theObject: currentModalType,
      variation,
      name: 'type'
    });
    setShowModal(false);
    setCurrentModalType(defaultType);
  };

  const renderOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.value}>
          {option.label}
        </option>
      );
    });
    return rows;
  };

  const handleTypeChange = (e, propertyName) => {
    setCurrentModalType({
      ...currentModalType,
      [propertyName]: e.target.value
    });
  };

  const deleteRowData = (data) => {
    updateSetupGroupsGenericFunction({
      updateType: 'delete',
      theObject: data,
      variation,
      name: 'type'
    });
  };

  return (
    <div>
      <div className="header">
        <h2>Types</h2>
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="personnel-information-container">
        <CustomTable
          numberOfRows={10}
          data={currentTypes}
          header={dataEntryTableColumns}
          action={actions}
          updateRowData={(data) => {
            setTypesToUpdate([...typesToUpdate, data]);
          }}
          deleteRowData={(data) => {
            deleteRowData(data);
          }}
          sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
        />
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Type',
              () => setShowModal(true),
              SCREEN.TYPE,
              RULES.CREATE,
              permission
            )}
          </div>
          <div>
            <CSVDownloader
              type={Type.Button}
              className="btn btn-primary"
              filename={`Types - ${moment().format('YYYY-MM-DD')}.csv`}
              data={csvData}
            >
              Export CSV
            </CSVDownloader>
          </div>

          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permission,
            SCREEN.TYPE,
            RULES.CREATE
          )}
        </div>
        <div>
          {renderButtonWithPermissions(
            'Save data',
            () => saveTypes(),
            SCREEN.TYPE,
            RULES.CREATE,
            permission
          )}
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Select
                value={currentModalType.location}
                onChange={(e) => handleTypeChange(e, 'location')}
                aria-label="Default select example"
              >
                <option value="">Assign a location</option>
                {renderLocationOptions(allLocations)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={currentModalType.name}
                onChange={(e) => handleTypeChange(e, 'name')}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                value={currentModalType.notes}
                onChange={(e) => handleTypeChange(e, 'notes')}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowModal(false)} />
          <PrimaryButton text="Save" clicked={() => handleTypeAdd()} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TypeSection;
