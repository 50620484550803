import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import moment from 'moment';
import './PrintAssessmentPage.scss';
import { useLocation } from 'react-router-dom';
import { requireAuth } from '../../util/auth';
import CustomTable from '../Table/CustomTable';
import { DataGrid } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';

function PrintInitialAssessmentPage() {
  const location = useLocation();
  const {
    modalAssessment,
    summaryStatisticsAssessmentColumns,
    currentMethod,
    currentInstrument,
    currentParameter
  } = location.state || {};
  const pageSizeDefault = 20;

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 500);
  }, []);

  const updatedColumns = summaryStatisticsAssessmentColumns.map(({ flex, ...col }, index) => {
    if (col.field === 'statistic') {
      return {
        ...col,
        width: 200
      };
    }
    return {
      ...col,
      flex: 1,
      width: 800 / (summaryStatisticsAssessmentColumns.length - 1)
    };
  });

  function getRowId(row) {
    if (row.id) {
      return row.id;
    }
    return randomId();
  }

  return (
    <>
      <Row className="report-header">
        <h4>Initial Assessment Report</h4>
      </Row>
      <Row className="ia-table" style={{ paddingLeft: '32px' }}>
        <Col>
          <Row>
            <Col>Method:</Col>
            <Col>{currentMethod}</Col>
          </Row>
          <Row>
            <Col>Instrument:</Col>
            <Col>{currentInstrument}</Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>Date:</Col>
            <Col>{moment(modalAssessment.created).format('MM/DD/YYYY')}</Col>
          </Row>
          <Row>
            <Col>Assessment by:</Col>
            <Col>{modalAssessment?.createdBy}</Col>
          </Row>
        </Col>
      </Row>
      <Row className="report-header">
        <h4>Summary Statistics</h4>
      </Row>
      <Row className="statistics-table">
        <div className="custom-table" style={{ width: 'fit-content' }}>
          <DataGrid
            rows={modalAssessment.summaryStatistics}
            columns={updatedColumns}
            getRowId={getRowId}
            hideFooter={true}
            sx={{ width: '1000px' }}
          />
        </div>
      </Row>
      <Row className="report-header">
        <h4>Assessment</h4>
      </Row>
      <div className="assessment-row">
        <div>
          <h6 className="ia-table" style={{ marginLeft: '50px' }}>
            1. Is any trending in an upward or downward direction evident?
          </h6>
        </div>
        <div className="assessment-row-btn-group">
          <ToggleButton
            key="radioSR-pass"
            id="radioSR-pass"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.trendingDirectionEvident === 'Pass'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Pass"
            checked={modalAssessment.trendingDirectionEvident === 'Pass'}
          >
            Pass
          </ToggleButton>
          <ToggleButton
            key="radioSR-fail"
            id="radioSR-fail"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.trendingDirectionEvident === 'Fail'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Fail"
            checked={modalAssessment.trendingDirectionEvident === 'Fail'}
          >
            Fail
          </ToggleButton>
        </div>
      </div>
      <div className="assessment-row">
        <div>
          <h6 className="ia-table" style={{ marginLeft: '50px' }}>
            2. Is any clustering evident?
          </h6>
        </div>
        <div className="assessment-row-btn-group">
          <ToggleButton
            key="radioUP-pass"
            id="radioUP-pass"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.clusteringEvident === 'Pass'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Pass"
            checked={modalAssessment.clusteringEvident === 'Pass'}
          >
            Pass
          </ToggleButton>
          <ToggleButton
            key="radioUP-fail"
            id="radioUP-fail"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.clusteringEvident === 'Fail'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Fail"
            checked={modalAssessment.clusteringEvident === 'Fail'}
          >
            Fail
          </ToggleButton>
        </div>
      </div>
      <div className="assessment-row">
        <div>
          <h6 className="ia-table" style={{ marginLeft: '50px' }}>
            3. Are any cyclical patterns evident?
          </h6>
        </div>
        <div className="assessment-row-btn-group">
          <ToggleButton
            key="radioTE-pass"
            id="radioTE-pass"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.cyclicalPatterns === 'Pass'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Pass"
            checked={modalAssessment.cyclicalPatterns === 'Pass'}
          >
            Pass
          </ToggleButton>
          <ToggleButton
            key="radioTE-fail"
            id="radioTE-fail"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.cyclicalPatterns === 'Fail'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Fail"
            checked={modalAssessment.cyclicalPatterns === 'Fail'}
          >
            Fail
          </ToggleButton>
        </div>
      </div>
      <div className="assessment-row">
        <div>
          <h6 className="ia-table" style={{ marginLeft: '50px' }}>
            4. Is the data normal or approximately normal in distribution?
          </h6>
        </div>
        <div className="assessment-row-btn-group">
          <ToggleButton
            key="radioCST-pass"
            id="radioCST-pass"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.normalDistribution === 'Pass'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Pass"
            checked={modalAssessment.normalDistribution === 'Pass'}
          >
            Pass
          </ToggleButton>
          <ToggleButton
            key="radioCST-fail"
            id="radioCST-fail"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.normalDistribution === 'Fail'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Fail"
            checked={modalAssessment.normalDistribution === 'Fail'}
          >
            Fail
          </ToggleButton>
        </div>
      </div>
      <div className="assessment-row">
        <div>
          <h6 className="ia-table" style={{ marginLeft: '50px' }}>
            5. TPI Assessment
          </h6>
        </div>
        <div className="assessment-row-btn-group">
          <ToggleButton
            key="radioNPP-pass"
            id="radioNPP-pass"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.tpiAssessment === 'Pass'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Pass"
            checked={modalAssessment.tpiAssessment === 'Pass'}
          >
            Pass
          </ToggleButton>
          <ToggleButton
            key="radioNPP-fail"
            id="radioNPP-fail"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.tpiAssessment === 'Fail'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            value="Fail"
            checked={modalAssessment.tpiAssessment === 'Fail'}
          >
            Fail
          </ToggleButton>
        </div>
      </div>
      <Row>
        <Col lg={9}>
          <h6 className="ia-table" style={{ marginLeft: '50px' }}>
            Assessor comments
          </h6>
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <Form className="ia-table" style={{ marginLeft: '50px' }}>
            <Form.Group>
              <Form.Control
                className="comments"
                as="textarea"
                rows={2}
                aria-label="Basic example"
                value={modalAssessment.assessorComments}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <div className="assessment-row" style={{ marginTop: '15px', marginBottom: '15px' }}>
        <div>
          <h6 className="ia-table" style={{ marginLeft: '50px' }}>
            Assessment outcome
          </h6>
        </div>
        <div className="assessment-row-btn-group">
          <ToggleButton
            key="radioOutcome-pass"
            id="radioOutcome-pass"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.outcome === 'Pass' ? { backgroundColor: 'navy', color: 'white' } : {}
            }
            value="Pass"
            checked={modalAssessment.outcome === 'Pass'}
          >
            Pass
          </ToggleButton>
          <ToggleButton
            key="radioOutcome-fail"
            id="radioOutcome-fail"
            type="radio"
            variant="outline-primary"
            name="radio"
            style={
              modalAssessment.outcome === 'Fail' ? { backgroundColor: 'navy', color: 'white' } : {}
            }
            value="Fail"
            checked={modalAssessment.outcome === 'Fail'}
          >
            Fail
          </ToggleButton>
        </div>
      </div>
    </>
  );
}

export default requireAuth(PrintInitialAssessmentPage);
