// Helper to check if analytics is properly configured
export const isAnalyticsEnabled = () => {
  const host = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;
  const key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
  const enabled = Boolean(host && key);

  if (!enabled) {
    console.error('Missing required Posthog configuration. Analytics will be disabled.');
  }

  return enabled;
};

// Posthog configuration object
export const getPosthogConfig = () => ({
  apiKey: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  options: {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
  }
});
