import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Meta from '../components/Meta';
import { requireAuth } from '../util/auth';
import OrganizationSection from '../components/OrganizationSection/OrganizationSection';
import PersonnelSection from '../components/PersonnelSection/PersonnelSection';
import MethodSection from '../components/MethodSection/MethodSection';
import TypeSection from '../components/TypeSection/TypeSection';
import ProductSection from '../components/ProductSection/ProductSection';
import Section from '../components/Section';
import ParameterSection from '../components/ParameterSection/ParameterSection';

function SPCMDPage() {
  const [activeTab, setActiveTab] = useState('organization');
  const [loading, setLoading] = useState(false);

  const handleSelect = (e) => {
    setLoading(true);
    setActiveTab(e);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [activeTab]);

  function renderBottomSection(section) {
    switch (section) {
      case 'personnel':
        return <PersonnelSection variation="spc" />;
      case 'type':
        return <TypeSection variation="spc" />;
      case 'product':
        return <ProductSection variation="spc" />;
      case 'method':
        return <MethodSection variation="spc" />;
      case 'parameter':
        return <ParameterSection variation="spc" />;
      default:
        return <OrganizationSection variation="spc" />;
    }
  }

  return (
    <>
      <Meta title="SPC Master Data" />
      <Tabs
        defaultActiveKey="organization"
        id="uncontrolled-tab-example"
        className="tri-tabs"
        onSelect={handleSelect}
      >
        <Tab eventKey="organization" title="Organization" />
        <Tab eventKey="personnel" title="Personnel" />

        <Tab eventKey="type" title="Type" />
        <Tab eventKey="product" title="Product" />
        <Tab eventKey="method" title="Method" />
        <Tab eventKey="parameter" title="Parameter" />
      </Tabs>
      <Section bg="white" textColor="dark" size="lg" bgImage="" bgImageOpacity={1}>
        <div>
          {loading ? (
            <div className="text-center py-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            renderBottomSection(activeTab)
          )}
        </div>
      </Section>
    </>
  );
}

export default requireAuth(SPCMDPage);
