export const ROLES = {
  ADMIN: 'Administrator',
  VIEW: 'Read-only',
  CONTRIBUTION: 'Contributor'
};

export const RULES = {
  READ: 'read',
  WRITE: 'write',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  SAVE: 'save',
  VIEW: 'view'
};

export const UPDATE_TYPE = {
  ALL: 'all',
  CUSTOM: 'custom',
  NONE: 'none'
};

export const DEFAULT_ADMIN = {
  create: true,
  update: {
    type: UPDATE_TYPE.ALL
  },
  delete: true
};

export const DEFAULT_READ_ONLY = {
  create: false,
  update: {
    type: UPDATE_TYPE.NONE
  },
  delete: false
};

export const TABLE = {
  DATA: 'data',
  CONFIG: 'config',
  ASSESSMENTS: 'assessments',
  EXTERNAL_ILCP_CALCULATIONS: 'external_ilcp_calculations',
  EXTERNAL_ILCP_DATA: 'external_ilcp_data',
  EXTERNAL_ILCP_PROGRAM: 'external_ilcp_program',
  INDEX: 'index',
  INTERNAL_ILCP_DATA: 'internal_ilcp_data',
  INTERNAL_ILCP_METADATA: 'internal_ilcp_metadata',
  INTERNAL_ILCP_RUN: 'internal_ilcp_run',
  SILENCED: 'silenced',
  STDCMP_DATA: 'stdcmp_data',
  STDCMP_PROGRAM: 'stdcmp_program',
  SUMMARY: 'summary'
};

export const SCREEN = {
  DATA: 'data',
  CONFIG: 'config',
  DATA_ENTRY: 'config-scheduled_samples',
  ORGANIZTION: 'config-organization',
  METHOD: 'config-method',
  SAMPLE: 'config-sample',
  INSTRUMENT: 'config-instrument',
  NOTIFICATIONS: 'config-notifications',
  PERSONNEL: 'config-personnel',
  GROUP: 'config-group',
  CONTRIBUTOR: 'permission',
  REPORT: 'config-reports',
  COMPARISON: 'data-comparison',
  INSTRUMENT_EVENTS: 'instrument-events',
  INSTRUMENT_INSTRUMENTS: 'instrument-instruments',
  INSTRUMENT_SETTINGS: 'instrument-settings'
};

export const SCREEN_PERMISSION = [
  { value: 'data', label: 'QC Data and Charts - Overview' },
  { value: 'config', label: 'QC Data and Charts - Config' },
  { value: 'config-reports', label: 'QC Data and Charts - Report' },
  { value: 'assessments', label: 'QC Data and Charts - Assessments' },
  { value: 'config-scheduled_samples', label: 'QC Data and Charts - Data Entry' },
  { value: 'config-organization', label: 'Master Data - Organization' },
  { value: 'config-group', label: 'Master Data - Group' },
  { value: 'config-method', label: 'Master Data - Method' },
  { value: 'config-sample', label: 'Master Data - Sample' },
  { value: 'config-instrument', label: 'Master Data - Instrument' },
  { value: 'config-notifications', label: 'Notifications Manager' },
  { value: 'data-comparison', label: 'QC Data and Charts - Comparison' },
  { value: 'instrument-events', label: 'Instrument Manager - Events' },
  { value: 'instrument-instruments', label: 'Instrument Manager - Instruments' },
  { value: 'instrument-settings', label: 'Instrument Manager - Settings' }
];

export const CUSTOM_FIELD = {
  data: [
    { value: 'dateTime', lable: 'Date/Time' },
    { value: 'sampleId', lable: 'Result ID' },
    { value: 'personnel', lable: 'Personnel' },
    { value: 'setupData', lable: 'Setup Data' },
    { value: 'result', lable: 'Result' },
    { value: 'resultOmits', lable: 'Result Omits' },
    { value: 'omitFromSqc', lable: 'Omit SQC' },
    { value: 'silence', lable: 'Silence' },
    { value: 'omit1090', lable: 'Omit 1090' },
    { value: 'notes', lable: 'Notes' }
  ]
};

export const tableList = [
  { value: 'data', label: 'Data' },
  { value: 'config', label: 'Config' },
  { value: 'assessments', label: 'Assessments' },
  { value: 'external_ilcp_calculations', label: 'External ILCP Calculations' },
  { value: 'external_ilcp_data', label: 'External ILCP Data' },
  { value: 'external_ilcp_program', label: 'External ILCP Program' },
  { value: 'index', label: 'Index' },
  { value: 'internal_ilcp_data', label: 'Internal ILCP Data' },
  { value: 'internal_ilcp_metadata', label: 'Internal ILCP Metadata' },
  { value: 'internal_ilcp_run', label: 'Internal ILCP Run' },
  { value: 'silenced', label: 'Silenced' },
  { value: 'stdcmp_data', label: 'Stdcmp Data' },
  { value: 'stdcmp_program', label: 'Stdcmp Program' },
  { value: 'summary', label: 'Summary' }
];

export const DEFAULT_PERMISSION = {
  Contributor: {
    'config-notifications': {
      view: true,
      delete: false,
      update: {
        type: 'none'
      },
      create: false
    },
    'config-sample': {
      view: true,
      create: false,
      delete: false,
      update: {
        type: 'none'
      }
    },
    'config-method': {
      view: true,
      delete: false,
      update: {
        type: 'none'
      },
      create: false
    },
    'config-organization': {
      view: true,
      delete: false,
      create: false,
      update: {
        type: 'none'
      }
    },
    data: {
      view: true,
      update: {
        type: 'custom',
        fieldAllow: [
          'notes',
          'result',
          'omit1090',
          'silence',
          'omitFromSqc',
          'resultOmits',
          'setupData',
          'personnel',
          'sampleId',
          'dateTime'
        ]
      },
      create: true,
      delete: false
    },
    'config-group': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-instrument': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    assessments: {
      view: true,
      create: false,
      delete: false,
      update: {
        type: 'none'
      }
    },
    config: {
      view: true,
      delete: false,
      create: false,
      update: {
        type: 'none'
      }
    },
    'config-scheduled_samples': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-reports': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    'data-comparison': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    'instrument-events': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    'instrument-instruments': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    'instrument-settings': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    permissionType: 'Contributor'
  },
  Administrator: {
    'config-notifications': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'config-sample': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'config-method': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'config-organization': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    data: {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'config-group': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'config-instrument': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    assessments: {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    config: {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'config-scheduled_samples': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'config-reports': {
      view: true,
      create: true,
      update: {
        type: 'all'
      },
      delete: true
    },
    'data-comparison': {
      view: true,
      update: {
        type: 'all'
      },
      delete: true,
      create: true
    },
    'instrument-events': {
      view: true,
      update: {
        type: 'all'
      },
      delete: true,
      create: true
    },
    'instrument-instruments': {
      view: true,
      update: {
        type: 'all'
      },
      delete: true,
      create: true
    },
    'instrument-settings': {
      view: true,
      update: {
        type: 'all'
      },
      delete: true,
      create: true
    },
    permissionType: 'Administrator'
  },
  'Read-only': {
    'config-notifications': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-sample': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-method': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-organization': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    data: {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-group': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-instrument': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    assessments: {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    config: {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-scheduled_samples': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'config-reports': {
      view: true,
      create: false,
      update: {
        type: 'none'
      },
      delete: false
    },
    'data-comparison': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    'instrument-events': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    'instrument-instruments': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    'instrument-settings': {
      view: true,
      update: {
        type: 'none'
      },
      delete: false,
      create: false
    },
    permissionType: 'Read-only'
  }
};

export const archiveRegex = /_\d{4}(-\d{2}-\d{2})?$/;
export const FORMAT_KEY_TIME = 'MM/DD/YYYY HH:mm';
export const FORMAT_DATE_YYYYMMDD = 'YYYYMMDD';
