import { getAuth, signOut } from 'firebase/auth';
import { isUndefined } from 'lodash';
import { jStat } from 'jstat';
import Button from 'react-bootstrap/Button';
import { CircularProgress, Button as MuiButton } from '@mui/material';
import Spinner from 'react-bootstrap/Spinner';
import firebaseApp from './firebase';
import { RULES, UPDATE_TYPE, ROLES } from './Constant';
import { v4 as uuidv4 } from 'uuid';
import { randomId } from '@mui/x-data-grid-generator';
import moment from 'moment';

const auth = getAuth(firebaseApp);

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

export async function apiRequest(path, method = 'GET', data) {
  const accessToken = auth.currentUser ? await auth.currentUser.getIdToken() : undefined;

  return fetch(`/api/${path}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: data ? JSON.stringify(data) : undefined
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 'error') {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === 'auth/invalid-user-token') {
          signOut(auth);
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

export const textSort = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const randomHexColorCode = () => {
  const n = (Math.random() * 0xfffff * 1000000).toString(16);
  return `#${n.slice(0, 6)}`;
};

export const randomDarkColor = () => {
  return 'hsla(' + Math.floor(Math.random() * 360) + ', 100%, 25%, 1)';
};

export const randomLightColor = (number) => {
  const hue = number * 137.508;
  return `hsl(${hue},50%,50%)`;
};

export const arrayStandardDeviation = (arrIn) => {
  let arr = arrIn;
  // Creating the mean with Array.reduce
  const mean =
    arr.reduce((acc, curr) => {
      return acc + curr;
    }, 0) / arr.length;

  // Assigning (value - mean) ^ 2 to every array item
  arr = arr.map((k) => {
    return (k - mean) ** 2;
  });

  // Calculating the sum of updated array
  const sum = arr.reduce((acc, curr) => acc + curr, 0);

  // Returning the Standard deviation
  return Math.sqrt(sum / arr.length);
};

export const arrayAverage = (arrIn) => {
  const arr = arrIn.filter((a) => !Number.isNaN(a) && a != null && a !== '---');
  return arr.reduce((a, b) => a + b, 0) / arr.length;
};

export const calculateHistogram = (chartData, dataMin, dataMax) => {
  // populate bin, count, normalPDF
  const n = chartData.length;
  if (n < 10) {
    // no histogram
    return null;
  }

  const histogramData = [];

  // number of bins
  const k = 1 + 3.322 * Math.log10(n);
  const interval = (dataMax - dataMin) / k;
  const dataValues = chartData.map((cd) => Number(cd.resultOmits));
  const dataAvg = arrayAverage(dataValues);
  const dataStd = arrayStandardDeviation(dataValues);

  for (let i = 1; i <= k; i += 1) {
    const binStart = dataMin + (i - 1) * interval;
    const binEnd = dataMin + i * interval;
    const binItems = chartData
      .filter((cd) => cd.resultOmits >= binStart && cd.resultOmits <= binEnd)
      .map((cd) => cd.resultOmits);

    const binZ = (binEnd - dataAvg) / dataStd;
    const binP = jStat.normal.pdf(binZ, 0, 1);
    histogramData.push({
      bin: i,
      count: binItems.length,
      normalPDF: Math.round(binP * n * 1000) / 1000
    });
  }

  return histogramData;
}; // calculateHistogram

export function linearRegression(y, x) {
  const lr = {};
  const n = y.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  let sumYY = 0;

  for (let i = 0; i < y.length; i += 1) {
    sumX += x[i];
    sumY += y[i];
    sumXY += x[i] * y[i];
    sumXX += x[i] * x[i];
    sumYY += y[i] * y[i];
  }

  lr.slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  lr.intercept = (sumY - lr.slope * sumX) / n;
  // eslint-disable-next-line no-restricted-properties
  lr.r2 = Math.pow(
    (n * sumXY - sumX * sumY) / Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY)),
    2
  );

  return lr;
}

export const checkReadOnlyTable = (table) => {
  if (table) {
    if (!!table[RULES.CREATE]) {
      return false;
    }
    if (!!table[RULES.DELETE]) {
      return false;
    }
    if (table[RULES.UPDATE] && table[RULES.UPDATE].type !== UPDATE_TYPE.NONE) {
      return false;
    }
  }
  return true;
};

export const checkReadOnly = (permissionData, table) => {
  if (permissionData && permissionData[table]) {
    return checkReadOnlyTable(permissionData[table]);
  }
  return true;
};

export const checkDelete = (permission, table) => {
  if (permission) {
    const isAdmin = permission && permission.permissionType === ROLES.ADMIN;
    if (isAdmin) {
      return isAdmin;
    }
    if (permission[table]) {
      if (!!permission[table][RULES.DELETE]) {
        return true;
      }
    }
  }
  return false;
};

export const checkCreate = (permission, table) => {
  if (permission) {
    const isAdmin = permission && permission.permissionType === ROLES.ADMIN;
    if (isAdmin) {
      return isAdmin;
    }
    if (permission[table]) {
      if (!!permission[table][RULES.CREATE]) {
        return true;
      }
    }
  }
  return false;
};

export const checkUpdate = (permission, table) => {
  if (permission) {
    const isAdmin = permission && permission.permissionType === ROLES.ADMIN;
    if (isAdmin) {
      return isAdmin;
    }
    if (permission[table]) {
      if (
        permission[table][RULES.UPDATE] &&
        permission[table][RULES.UPDATE].type !== UPDATE_TYPE.NONE
      ) {
        return true;
      }
    }
  }
  return false;
};

export const checkHasPermission = (permission, type) => {
  if (permission) {
    if (type === RULES.SAVE) {
      return !checkReadOnlyTable(permission);
    }
    if (type === RULES.CREATE) {
      return !!permission[RULES.CREATE];
    }
    if (type === RULES.DELETE) {
      return !!permission[RULES.DELETE];
    }
    if (type === RULES.UPDATE) {
      return permission[RULES.UPDATE] && permission[RULES.UPDATE].type !== UPDATE_TYPE.NONE;
    }
    if (type === RULES.VIEW) {
      return !!permission[RULES.VIEW];
    }
  }
  return false;
};

export const isAdmin = (permission) => {
  if (permission) {
    const isAdmin = permission && permission.permissionType === ROLES.ADMIN;
    return isAdmin;
  }
  return false;
};

export const isContributor = (permission) => {
  if (permission) {
    const isContributor = permission && permission.permissionType === ROLES.CONTRIBUTION;
    return isContributor;
  }
  return false;
};

export const renderButtonWithPermissions = (
  buttonText,
  currentFunction,
  table,
  rule,
  permission,
  hasLoading = false,
  isLoading = false
) => {
  const isAdmin = permission && permission.permissionType === ROLES.ADMIN;
  if (isAdmin) {
    return (
      <Button disabled={hasLoading && isLoading} key={uuidv4()} onClick={currentFunction}>
        {hasLoading && isLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          ></Spinner>
        ) : (
          <span>{buttonText}</span>
        )}
      </Button>
    );
  }
  if (permission && permission[table]) {
    const hasPerMission = checkHasPermission(permission[table], rule);
    if (hasPerMission) {
      return (
        <Button key={uuidv4()} onClick={currentFunction} disabled={hasLoading && isLoading}>
          {hasLoading && isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <span>{buttonText}</span>
          )}
        </Button>
      );
    }
  }
  return <></>;
};

export const renderLoadingButton = ({
  buttonText,
  hasLoading = false,
  isLoading = false,
  ...otherProps
}) => {
  return (
    <Button key={uuidv4()} disabled={hasLoading && isLoading} {...otherProps}>
      {hasLoading && isLoading ? (
        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner>
      ) : (
        <span>{buttonText}</span>
      )}
    </Button>
  );
};

export const renderMUIButtonWithPermissions = (
  buttonText,
  currentFunction,
  table,
  rule,
  permission,
  isLoading = false
) => {
  const isAdmin = permission && permission.permissionType === ROLES.ADMIN;
  if (isAdmin) {
    return (
      <MuiButton variant="contained" disabled={isLoading} onClick={currentFunction}>
        {isLoading ? <CircularProgress size="1.5rem" /> : <span>{buttonText}</span>}
      </MuiButton>
    );
  }
  if (permission && permission[table]) {
    const hasPerMission = checkHasPermission(permission[table], rule);
    if (hasPerMission) {
      return (
        <MuiButton variant="contained" disabled={isLoading} onClick={currentFunction}>
          {isLoading ? <CircularProgress size="1.5rem" /> : <span>{buttonText}</span>}
        </MuiButton>
      );
    }
  }
  return <></>;
};

export const renderMUILoadingButton = ({
  buttonText,
  isLoading = false,
  disabled,
  ...otherProps
}) => {
  return (
    <MuiButton disabled={isLoading || disabled} {...otherProps}>
      {isLoading ? <CircularProgress size="1.5rem" /> : <span>{buttonText}</span>}
    </MuiButton>
  );
};

export const renderAuthButtonWithPermission = (
  handleResults,
  auth,
  currentPersonnel,
  permission,
  table,
  rule
) => {
  if (
    (permission && permission.permissionType === ROLES.ADMIN) ||
    (permission && permission[table] && checkHasPermission(permission[table], rule))
  ) {
    return <div> {auth.renderAddToFileWithPermissions(handleResults, auth, currentPersonnel)}</div>;
  }
};

export const uniqueArrayByKey = (arr, key) => {
  const uniqueMap = new Map();
  arr.forEach((obj) => {
    const keyValue = obj[key];
    if (!uniqueMap.has(keyValue)) {
      uniqueMap.set(keyValue, obj);
    }
  });
  return Array.from(uniqueMap.values());
};

export const setDefaultControlStrategies = (key, data) => {
  switch (key) {
    case 'EWMA':
      data.controlLimitViolation = 'Out of Control';
      data.warningLimitViolation = 'Off';
      data.twoOfThree = 'Off';
      data.fiveConsecutive = 'Off';
      data.nineConsecutive = 'Out of Control';
      data.sevenConsecutive = 'Off';
      data.ewmaTrend = 'Out of Control';
      data.controlLimitExceedance = 'Off';
      data.toleranceLimitExceedance = 'Off';
      data.singlePointViolation = 'Warning';
      data.fivePointViolation = 'Out of Control';
      data.andersonDarling = 'Off';
      data.tpiOnePointTwo = 'Off';
      break;
    case 'Run rules':
      data.controlLimitViolation = 'Out of Control';
      data.warningLimitViolation = 'Off';
      data.twoOfThree = 'Out of Control';
      data.fiveConsecutive = 'Out of Control';
      data.nineConsecutive = 'Out of Control';
      data.sevenConsecutive = 'Out of Control';
      data.ewmaTrend = 'Off';
      data.controlLimitExceedance = 'Off';
      data.toleranceLimitExceedance = 'Off';
      data.singlePointViolation = 'Warning';
      data.fivePointViolation = 'Out of Control';
      data.andersonDarling = 'Off';
      data.tpiOnePointTwo = 'Off';
      break;
    case 'Control limits':
      data.controlLimitViolation = 'Off';
      data.warningLimitViolation = 'Off';
      data.twoOfThree = 'Off';
      data.fiveConsecutive = 'Off';
      data.nineConsecutive = 'Off';
      data.sevenConsecutive = 'Off';
      data.ewmaTrend = 'Off';
      data.controlLimitExceedance = 'Out of Control';
      data.toleranceLimitExceedance = 'Off';
      data.singlePointViolation = 'Off';
      data.fivePointViolation = 'Off';
      data.andersonDarling = 'Off';
      data.tpiOnePointTwo = 'Off';
      break;
    default:
      data.controlLimitViolation = 'Off';
      data.warningLimitViolation = 'Off';
      data.twoOfThree = 'Off';
      data.fiveConsecutive = 'Off';
      data.nineConsecutive = 'Off';
      data.sevenConsecutive = 'Off';
      data.ewmaTrend = 'Off';
      data.controlLimitExceedance = 'Off';
      data.toleranceLimitExceedance = 'Out of Control';
      data.singlePointViolation = 'Off';
      data.fivePointViolation = 'Off';
      data.andersonDarling = 'Off';
      data.tpiOnePointTwo = 'Off';
      break;
  }
};

export const generateControlStrategyData = (updatedCurrentControlStrategyConfigurations) => {
  return [
    {
      id: randomId(),
      variable: 'Control limit violation (3 sigma)',
      value: updatedCurrentControlStrategyConfigurations.controlLimitViolation
    },
    {
      id: randomId(),
      variable: 'Warning limit violation (2 sigma)',
      value: updatedCurrentControlStrategyConfigurations.warningLimitViolation
    },
    {
      id: randomId(),
      variable: '2 of 3 points > 2 sigma',
      value: updatedCurrentControlStrategyConfigurations.twoOfThree
    },
    {
      id: randomId(),
      variable: '5 consecutive points > 1 sigma',
      value: updatedCurrentControlStrategyConfigurations.fiveConsecutive
    },
    {
      id: randomId(),
      variable: '9 consecutive points above/below centerline',
      value: updatedCurrentControlStrategyConfigurations.nineConsecutive
    },
    {
      id: randomId(),
      variable: '7 consecutive points increasing / decreasing',
      value: updatedCurrentControlStrategyConfigurations.sevenConsecutive
    },
    {
      id: randomId(),
      variable: 'EWMA trend',
      value: updatedCurrentControlStrategyConfigurations.ewmaTrend
    },
    {
      id: randomId(),
      variable: 'User defined control limit exceedance',
      value: updatedCurrentControlStrategyConfigurations.controlLimitExceedance
    },
    {
      id: randomId(),
      variable: 'Tolerance limit exceedance',
      value: updatedCurrentControlStrategyConfigurations.toleranceLimitExceedance
    },
    {
      id: randomId(),
      variable: 'Moving range limit violation (single point)',
      value: updatedCurrentControlStrategyConfigurations.singlePointViolation
    },
    {
      id: randomId(),
      variable: 'Moving range limit violation (5 of 20 points)',
      value: updatedCurrentControlStrategyConfigurations.fivePointViolation
    },
    {
      id: randomId(),
      variable: 'Anderson Darling exceeds exceeds 1.13',
      value: updatedCurrentControlStrategyConfigurations.andersonDarling
    },
    {
      id: randomId(),
      variable: 'TPI exceeds 1.2',
      value: updatedCurrentControlStrategyConfigurations.tpiOnePointTwo
    }
  ];
};

export const createCustomizedDot = (
  cx,
  cy,
  payload,
  setupDataChecked,
  isWarning,
  isOOC,
  warningFlagsChecked,
  outOfControlChecked
) => {
  if (!cx || !cy) {
    return null;
  }
  const dotToReturn = [];
  if (payload.setupData === 1 && setupDataChecked) {
    dotToReturn.push(
      <svg
        x={cx - 3}
        y={cy - 3}
        key={uuidv4()}
        width={15}
        height={15}
        fill="#B0E0E6"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
      </svg>
    );
  } else if (isWarning && !isOOC && warningFlagsChecked) {
    dotToReturn.push(
      <svg
        x={cx - 3}
        y={cy - 3}
        width={15}
        height={15}
        key={uuidv4()}
        fill="yellow"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
      </svg>
    );
  } else if (!isWarning && isOOC && outOfControlChecked) {
    dotToReturn.push(
      <svg
        x={cx - 3}
        y={cy - 3}
        width={15}
        height={15}
        key={uuidv4()}
        fill="red"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
      </svg>
    );
  } else if (isWarning && isOOC && warningFlagsChecked && outOfControlChecked) {
    dotToReturn.push(
      <svg
        x={cx - 3}
        y={cy - 3}
        width={15}
        height={15}
        key={uuidv4()}
        fill="red"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
      </svg>
    );
  } else if (isWarning && isOOC && !warningFlagsChecked && outOfControlChecked) {
    dotToReturn.push(
      <svg
        x={cx - 3}
        y={cy - 3}
        width={15}
        height={15}
        key={uuidv4()}
        fill="red"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
      </svg>
    );
  } else if (isWarning && isOOC && warningFlagsChecked && !outOfControlChecked) {
    dotToReturn.push(
      <svg
        x={cx - 3}
        y={cy - 3}
        width={15}
        height={15}
        key={uuidv4()}
        fill="yellow"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
      </svg>
    );
  } else {
    dotToReturn.push(
      <svg
        x={cx - 3}
        y={cy - 3}
        width={15}
        key={uuidv4()}
        height={15}
        fill="green"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
      </svg>
    );
  }
  return dotToReturn;
};

export const getFacilityLabelByValue = (locations, locationCode) => {
  if (locations && locations.size > 0) {
    const locationData = locations.get(locationCode);
    if (locationData) {
      return locationData.locationName;
    }
  }
  return locationCode;
};

export const dateTimeComparator = (v1, v2) => {
  const d1 = new Date(v1).getTime();
  const d2 = new Date(v2).getTime();
  return d1 - d2;
};

export const firebaseClean = (name) => {
  return String(name).replace(/[.#$/[\]]/g, '');
};

export function alphaNumericSorter(a, b) {
  return a.name.localeCompare(b.name, undefined, { numeric: true });
}

export function alphaNumericSorterArrayString(a, b) {
  return a.localeCompare(b, undefined, { numeric: true });
}

export function formatCurrency(number) {
  if (!number) {
    return '';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
}

export const dateFormat = (date) => (date.includes('/') ? 'M/D/YYYY' : 'YYYY-MM-DD');

export const setCurrentTime = (dateOnly) =>
  dateOnly
    .set('hours', moment().get('hours'))
    .set('minutes', moment().get('minutes'))
    .set('seconds', moment().get('seconds'));

export const getSigDigitsUtil = (stringValue, chartSigDigits) => {
  const valueToReturn = parseFloat(stringValue);
  if (Number.isNaN(valueToReturn)) {
    return '';
  }
  // Handle edge case of 0 significant digits
  if (chartSigDigits <= 0) {
    if (valueToReturn < 1) {
      return '0';
    }
    return valueToReturn.toPrecision(1);
  }
  if (valueToReturn < 1) {
    return valueToReturn.toFixed(chartSigDigits - 1);
  }
  return valueToReturn.toPrecision(chartSigDigits);
};

export const getTimeZone = (featureFlags) => {
  // timezone selection; could be moved out somewhere else if used more widely
  let tz = 'UTC';
  for (const featureFlag of featureFlags) {
    if (featureFlag.startsWith('tz=')) {
      tz = featureFlag.substring(3);
    }
  }
  return tz;
  // end timezone selection
};

export const sanitizePropertyData = (property) => {
  if (isUndefined(property)) {
    return '';
  }
  return property;
};
