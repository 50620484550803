import { randomId } from '@mui/x-data-grid-generator';
import moment from 'moment-timezone';

export const dayTimeComparator = (v1, v2) => {
  const d1 = new Date(v1).getTime();
  const d2 = new Date(v2).getTime();
  return d1 - d2;
};

export const yesNoOptions = [
  {
    value: 'N',
    label: 'N'
  },
  {
    value: 'Y',
    label: 'Y'
  }
];

export const defaultColumnsExtend = [
  {
    field: 'omit1090',
    headerName: 'Omit 1090',
    minWidth: 100
  },
  {
    field: 'notes',
    headerName: 'Notes',
    minWidth: 300,
    flex: 1
  }
];

export const defaultColumnsUsExtend = [
  {
    field: 'notes',
    headerName: 'Notes',
    minWidth: 300
  }
];

export const defaultColumns = (dateTimeFormat, tz = 'UTC') => {
  return [
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortingOrder: ['desc', 'asc'],
      sortComparator: dayTimeComparator,
      renderCell: (params) => {
        return moment(params.value).tz(tz).format(dateTimeFormat);
      },
      minWidth: 200
    },
    {
      field: 'sampleId',
      headerName: 'Result ID',
      minWidth: 170
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      minWidth: 100
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      minWidth: 100
    },
    {
      field: 'flags',
      headerName: 'Flags',
      minWidth: 150
    },
    {
      field: 'result',
      headerName: 'Result',
      minWidth: 100
    },
    {
      field: 'resultOmits',
      headerName: 'Result (Omits)',
      minWidth: 125
    },
    {
      field: 'omitFromSqc',
      headerName: 'Omit SQC',
      minWidth: 100
    },
    {
      field: 'silence',
      headerName: 'Silence',
      minWidth: 100
    }
  ];
};

export const defaultColumnsReport = [
  {
    field: 'dateTime',
    headerName: 'Date/Time',
    sortComparator: dayTimeComparator,
    width: 170
  },
  {
    field: 'sampleId',
    headerName: 'Result ID',
    width: 130
  },
  {
    field: 'personnel',
    headerName: 'Personnel',
    width: 100
  },
  {
    field: 'setupData',
    headerName: 'Setup Data',
    width: 70
  },
  {
    field: 'flags',
    headerName: 'Flags',
    width: 130
  },
  {
    field: 'result',
    headerName: 'Result',
    width: 70
  },
  {
    field: 'resultOmits',
    headerName: 'Result (Omits)',
    width: 90
  },
  {
    field: 'omitFromSqc',
    headerName: 'Omit SQC',
    width: 90
  },
  {
    field: 'silence',
    headerName: 'Silence',
    width: 70
  },
  {
    field: 'omit1090',
    headerName: 'Omit 1090',
    width: 90
  },
  {
    field: 'notes',
    headerName: 'Notes',
    sort: true,
    flex: 1
  }
];

export const defaultColumnsEdit = (currentPersonnelDropdown, dateTimeFormat, tz = 'UTC') => {
  return [
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortingOrder: ['desc', 'asc'],
      sortComparator: dayTimeComparator,
      valueGetter: (params) => {
        return moment(params.value).tz(tz).format(dateTimeFormat);
      },
      minWidth: 200,
      editable: true
    },
    {
      field: 'sampleId',
      headerName: 'Result ID',
      minWidth: 170,
      editable: true
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      minWidth: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: currentPersonnelDropdown
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      minWidth: 100,
      editable: true
    },
    {
      field: 'flags',
      headerName: 'Flags',
      minWidth: 250,
      editable: false
    },
    {
      field: 'result',
      headerName: 'Result',
      minWidth: 100,
      editable: true
    },
    {
      field: 'resultOmits',
      headerName: 'Result (Omits)',
      minWidth: 120,
      editable: true
    },
    {
      field: 'omitFromSqc',
      headerName: 'Omit SQC',
      minWidth: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: yesNoOptions
    },
    {
      field: 'silence',
      headerName: 'Silence',
      minWidth: 80,
      editable: true,
      type: 'singleSelect',
      valueOptions: yesNoOptions
    }
  ];
};

export const spcDefaultColumns = (dateTimeFormat, tz = 'UTC') => {
  return [
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortingOrder: ['desc', 'asc'],
      sortComparator: dayTimeComparator,
      renderCell: (params) => {
        return moment(params.value).tz(tz).format(dateTimeFormat);
      },
      minWidth: 200
    },
    {
      field: 'sampleId',
      headerName: 'Result ID',
      minWidth: 170
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      minWidth: 100
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      minWidth: 100
    },
    {
      field: 'flags',
      headerName: 'Flags',
      minWidth: 150
    },
    {
      field: 'result',
      headerName: 'Result',
      minWidth: 100
    },
    {
      field: 'dateAverage',
      headerName: 'Average',
      minWidth: 100
    },
    {
      field: 'dateRange',
      headerName: 'Range',
      minWidth: 100
    },
    {
      field: 'omitFromSqc',
      headerName: 'Omit SQC',
      minWidth: 100
    },
    {
      field: 'silence',
      headerName: 'Silence',
      minWidth: 100
    }
  ];
};

export const spcDefaultColumnsReport = [
  {
    field: 'dateTime',
    headerName: 'Date/Time',
    sortComparator: dayTimeComparator,
    width: 170
  },
  {
    field: 'sampleId',
    headerName: 'Result ID',
    width: 130
  },
  {
    field: 'personnel',
    headerName: 'Personnel',
    width: 100
  },
  {
    field: 'setupData',
    headerName: 'Setup Data',
    width: 70
  },
  {
    field: 'flags',
    headerName: 'Flags',
    width: 130
  },
  {
    field: 'result',
    headerName: 'Result',
    width: 70
  },
  {
    field: 'average',
    headerName: 'Average',
    minWidth: 90
  },
  {
    field: 'range',
    headerName: 'Range',
    minWidth: 90
  },
  {
    field: 'omitFromSqc',
    headerName: 'Omit SQC',
    width: 90
  },
  {
    field: 'silence',
    headerName: 'Silence',
    width: 70
  },
  {
    field: 'omit1090',
    headerName: 'Omit 1090',
    width: 90
  },
  {
    field: 'notes',
    headerName: 'Notes',
    sort: true,
    flex: 1
  }
];

export const spcDefaultColumnsEdit = (currentPersonnelDropdown, dateTimeFormat, tz = 'UTC') => {
  return [
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortingOrder: ['desc', 'asc'],
      sortComparator: dayTimeComparator,
      valueGetter: (params) => {
        return moment(params.value).tz(tz).format(dateTimeFormat);
      },
      minWidth: 200,
      editable: true
    },
    {
      field: 'sampleId',
      headerName: 'Result ID',
      minWidth: 170,
      editable: true
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      minWidth: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: currentPersonnelDropdown
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      minWidth: 100,
      editable: true
    },
    {
      field: 'flags',
      headerName: 'Flags',
      minWidth: 250,
      editable: false
    },
    {
      field: 'result',
      headerName: 'Result',
      minWidth: 100,
      editable: true
    },
    {
      field: 'average',
      headerName: 'Average',
      minWidth: 100,
      editable: false
    },
    {
      field: 'range',
      headerName: 'Range',
      minWidth: 100,
      editable: false
    },
    {
      field: 'omitFromSqc',
      headerName: 'Omit SQC',
      minWidth: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: yesNoOptions
    },
    {
      field: 'silence',
      headerName: 'Silence',
      minWidth: 80,
      editable: true,
      type: 'singleSelect',
      valueOptions: yesNoOptions
    }
  ];
};

export const resultsDataTableColumnsNew = [
  {
    field: 'date',
    headerName: 'Date',
    editable: false
  },
  {
    field: 'parameter',
    headerName: 'Parameter',
    editable: false
  },
  {
    field: 'personnel',
    headerName: 'Personnel',
    editable: false
  },
  {
    field: 'sampleId',
    headerName: 'Sample ID',
    editable: false
  },
  {
    field: 'setupData',
    headerName: 'Setup Data',
    editable: false
  },
  {
    field: 'notes',
    headerName: 'Notes',
    editable: false
  },
  {
    field: 'result',
    headerName: 'Result',
    editable: true
  }
];

export const resultsDataTableColumnsDefault = [
  {
    dataField: 'date',
    text: 'Date'
  },
  {
    dataField: 'parameter',
    text: 'Parameter'
  },
  {
    dataField: 'personnel',
    text: 'Personnel'
  },
  {
    dataField: 'sampleId',
    text: 'Result ID'
  },
  {
    dataField: 'setupData',
    text: 'Setup Data'
  },
  {
    dataField: 'notes',
    text: 'Notes'
  }
];

export const resultsDataTableDataDefault = [
  {
    date: 'Current date',
    parameter: '10 evap',
    personnel: 'Current user',
    sampleId: 'Previous',
    setupData: '0',
    notes: '',
    result: ''
  },
  {
    date: 'Current date',
    parameter: '10 evap',
    personnel: 'Current user',
    sampleId: 'Previous',
    setupData: '0',
    notes: '',
    result: ''
  },
  {
    date: 'Current date',
    parameter: '10 evap',
    personnel: 'Current user',
    sampleId: 'Previous',
    setupData: '0',
    notes: '',
    result: ''
  },
  {
    date: 'Current date',
    parameter: '10 evap',
    personnel: 'Current user',
    sampleId: 'Previous',
    setupData: '0',
    notes: '',
    result: ''
  },
  {
    date: 'Current date',
    parameter: '10 evap',
    personnel: 'Current user',
    sampleId: 'Previous',
    setupData: '0',
    notes: '',
    result: ''
  },
  {
    date: 'Current date',
    parameter: '10 evap',
    personnel: 'Current user',
    sampleId: 'Previous',
    setupData: '0',
    notes: '',
    result: ''
  }
];

export const summaryStatisticsData = [
  {
    statistic: 'Average',
    ibp: '90',
    tenevap: '110',
    fiftyevap: '130',
    ninetyevap: '180',
    fbp: '200'
  },
  {
    statistic: 'Standard Deviation, RMS',
    ibp: '1.30',
    tenevap: '2.00',
    fiftyevap: '2.50',
    ninetyevap: '3.00',
    fbp: '4.00'
  },
  {
    statistic: 'Standard Deviation, MR',
    ibp: '',
    tenevap: '',
    fiftyevap: '',
    ninetyevap: '',
    fbp: ''
  },
  {
    statistic: 'Sample Size (n)',
    ibp: '20',
    tenevap: '20',
    fiftyevap: '20',
    ninetyevap: '20',
    fbp: '20'
  },
  {
    statistic: 'Number of outliers',
    ibp: '1',
    tenevap: '0',
    fiftyevap: '0',
    ninetyevap: '1',
    fbp: '0'
  },
  {
    statistic: 'Runs Omitted',
    ibp: '1',
    tenevap: '1',
    fiftyevap: '1',
    ninetyevap: '1',
    fbp: '1'
  },
  {
    statistic: 'Repeatability (r)',
    ibp: '4.00',
    tenevap: '6.00',
    fiftyevap: '7.00',
    ninetyevap: '9.00',
    fbp: '12.00'
  },
  {
    statistic: 'Reproducibility (R)',
    ibp: '8.00',
    tenevap: '12.00',
    fiftyevap: '14.00',
    ninetyevap: '18.00',
    fbp: '24.00'
  },
  {
    statistic: 'Site Precision',
    ibp: '3.60',
    tenevap: '5.54',
    fiftyevap: '6.93',
    ninetyevap: '8.31',
    fbp: '11.08'
  },
  {
    statistic: 'Precision Ratio',
    ibp: '1.20',
    tenevap: '1.40',
    fiftyevap: '1.50',
    ninetyevap: '1.60',
    fbp: '1.70'
  },
  {
    statistic: 'TPI',
    ibp: '0.90',
    tenevap: '1.10',
    fiftyevap: '2.00',
    ninetyevap: '2.10',
    fbp: '1.70'
  },
  {
    statistic: 'TPI Assessment',
    ibp: 'Marginal',
    tenevap: 'Acceptable',
    fiftyevap: 'Acceptable',
    ninetyevap: 'Acceptable',
    fbp: 'Acceptable'
  },
  {
    statistic: 'Anderson Darling',
    ibp: '1.10',
    tenevap: '1.00',
    fiftyevap: '0.90',
    ninetyevap: '0.78',
    fbp: '1.30'
  },
  {
    statistic: 'Normality Assessment',
    ibp: 'Normal',
    tenevap: 'Marginal',
    fiftyevap: 'Marginal',
    ninetyevap: 'Non-normal',
    fbp: 'Normal'
  }
];

export const summaryStatisticsPreview = [
  {
    statistic: 'Average',
    ibp: '90',
    tenevap: '110',
    fiftyevap: '130',
    ninetyevap: '180',
    fbp: '200'
  },
  {
    statistic: 'Standard Deviation, RMS',
    ibp: '1.30',
    tenevap: '2.00',
    fiftyevap: '2.50',
    ninetyevap: '3.00',
    fbp: '4.00'
  },
  {
    statistic: 'Standard Deviation, MR',
    ibp: '1.10',
    tenevap: '',
    fiftyevap: '',
    ninetyevap: '',
    fbp: ''
  },
  {
    statistic: 'Repeatability (r)',
    ibp: '4.00',
    tenevap: '6.00',
    fiftyevap: '7.00',
    ninetyevap: '9.00',
    fbp: '12.00'
  }
];

export const summaryStatisticsPreviewTwo = [
  {
    statistic: 'Reproducibility (R)',
    ibp: '8.00',
    tenevap: '12.00',
    fiftyevap: '14.00',
    ninetyevap: '18.00',
    fbp: '24.00'
  },
  {
    statistic: 'Precision Ratio',
    ibp: '1.20',
    tenevap: '1.40',
    fiftyevap: '1.50',
    ninetyevap: '1.60',
    fbp: '1.70'
  },
  {
    statistic: 'TPI',
    ibp: '0.90',
    tenevap: '1.10',
    fiftyevap: '2.00',
    ninetyevap: '2.10',
    fbp: '1.70'
  },
  {
    statistic: 'Anderson Darling',
    ibp: '1.10',
    tenevap: '1.00',
    fiftyevap: '0.90',
    ninetyevap: '0.78',
    fbp: '1.30'
  }
];

export const summaryStatisticsDataPeriodic = [
  {
    id: randomId(),
    statistic: 'Sample Size (n)',
    setupData: '60',
    recentData: '20',
    pooledData: '80',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'Average',
    setupData: '89.6',
    recentData: '89.395',
    pooledData: '89.50',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'Standard Deviation, RMS',
    setupData: '0.60',
    recentData: '0.67',
    pooledData: '0.63',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'Standard Deviation, MR',
    setupData: '0.630',
    recentData: '0.697',
    pooledData: '0.66',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'GESD outlier (count)',
    setupData: '1',
    recentData: '0',
    pooledData: '1',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'Runs Omitted',
    setupData: '1',
    recentData: '0',
    pooledData: '1',
    evaluation: ''
  },
  {
    id: randomId(),
    istic: 'Repeatability (r)',
    setupData: '2.00',
    recentData: '2.00',
    pooledData: '2.00',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'Reprodubility (R)',
    setupData: '4.00',
    recentData: '4.00',
    pooledData: '4.00',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'Site Precision',
    setupData: '1.66',
    recentData: '1.85',
    pooledData: '1.75',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'Precision Ratio',
    setupData: '1.20',
    recentData: '1.12',
    pooledData: '1.16',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'TPI',
    setupData: '2.41',
    recentData: '2.17',
    pooledData: '2.29',
    evaluation: 'Pass'
  },
  {
    id: randomId(),
    statistic: 'Anderson Darling (RMS)',
    setupData: '1.10',
    recentData: '1.13',
    pooledData: '1.12',
    evaluation: 'Marginal'
  },
  {
    id: randomId(),
    statistic: 'Chi-squared (RMS)',
    setupData: '3.16',
    recentData: '3.78',
    pooledData: '7.12',
    evaluation: 'Pass'
  },
  {
    id: randomId(),
    statistic: 'Chi-squared (critical)',
    setupData: '95.10',
    recentData: '46.20',
    pooledData: '132.10',
    evaluation: 'Pass'
  },
  {
    id: randomId(),
    statistic: 't-test',
    setupData: '',
    recentData: '',
    pooledData: '-1.63',
    evaluation: 'Pass'
  },
  {
    id: randomId(),
    statistic: 't-critical',
    setupData: '',
    recentData: '',
    pooledData: '1.60',
    evaluation: 'Pass'
  },
  {
    id: randomId(),
    statistic: 'F-test',
    setupData: '',
    recentData: '',
    pooledData: '1.66',
    evaluation: 'Pass'
  },
  {
    id: randomId(),
    statistic: 'F-critical',
    setupData: '',
    recentData: '',
    pooledData: '1.74',
    evaluation: 'Pass'
  },
  {
    id: randomId(),
    statistic: 'UCL',
    setupData: '90.10',
    recentData: '90.40',
    pooledData: '90.25',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'LCL',
    setupData: '88.30',
    recentData: '88.40',
    pooledData: '88.35',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'UWL',
    setupData: '90.30',
    recentData: '89.90',
    pooledData: '90.10',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'LWL',
    setupData: '88.90',
    recentData: '88.90',
    pooledData: '88.90',
    evaluation: ''
  },
  {
    id: randomId(),
    statistic: 'MR UCL',
    setupData: '1.05',
    recentData: '1.27',
    pooledData: '1.16',
    evaluation: ''
  }
];

export const summaryStatisticsColumns = [
  {
    field: 'statistic',
    headerName: 'Statistic',
    flex: 1,
    editable: false
  },
  {
    field: 'ibp',
    headerName: 'IBP (F)',
    flex: 1,
    editable: false
  },
  {
    field: 'tenevap',
    headerName: '10 Evap (F)',
    flex: 1,
    editable: false
  },
  {
    field: 'fiftyevap',
    headerName: '50 Evap (F)',
    flex: 1,
    editable: false
  },
  {
    field: 'ninetyevap',
    headerName: '90 Evap (F)',
    flex: 1,
    editable: false
  },
  {
    field: 'fbp',
    headerName: 'FBP (F)',
    flex: 1,
    editable: false
  }
];

export const summaryStatisticsColumnsPeriodic = [
  {
    field: 'statistic',
    headerName: 'Statistic',
    sortable: false,
    width: 200
  },
  {
    field: 'setupData',
    headerName: 'Setup Data',
    sortable: false,
    width: 150
  },
  {
    field: 'recentData',
    headerName: 'Recent Data',
    sortable: false,
    width: 150
  },
  {
    field: 'pooledData',
    headerName: 'Pooled Data',
    sortable: false,
    width: 150
  },
  {
    field: 'evaluation',
    headerName: 'Evaluation',
    sortable: false,
    flex: 1
  }
];

export const wFlagColumns = [
  'wSigma3',
  'wMrSingle',
  'wMr520',
  'wSigma2',
  'wTwoThirds',
  'wSigma51',
  'wAb9',
  'wId7',
  'wEwmaTrend',
  'wUdRange',
  'wUdP',
  'wAd113',
  'wTpi12',
  'wTolLimit'
];

export const oocFlagColumns = [
  'oocSigma3',
  'oocMrSingle',
  'oocMr520',
  'oocSigma2',
  'oocTwoThirds',
  'oocSigma51',
  'oocAb9',
  'oocId7',
  'oocEwmaTrend',
  'oocUdRange',
  'oocUdP',
  'oocAd113',
  'oocTpi12',
  'oocTolLimit'
];

export const wFlagIChartColumns = [
  'wSigma3',
  'wSigma2',
  'wTwoThirds',
  'wSigma51',
  'wAb9',
  'wId7',
  'wEwmaTrend',
  'wUdRange',
  'wUdP',
  'wAd113',
  'wTpi12',
  'wTolLimit'
];

export const oocFlagIChartColumns = [
  'oocSigma3',
  'oocSigma2',
  'oocTwoThirds',
  'oocSigma51',
  'oocAb9',
  'oocId7',
  'oocEwmaTrend',
  'oocUdRange',
  'oocUdP',
  'oocAd113',
  'oocTpi12',
  'oocTolLimit'
];

export const wFlagMrCharttColumns = ['wMrSingle', 'wMr520'];

export const oocFlagMrCharttColumns = ['oocMrSingle', 'oocMr520'];

export const defaultExportDataToCSVColumns = [
  {
    field: 'location',
    headerName: 'Location'
  },
  {
    field: 'method',
    headerName: 'Method'
  },
  {
    field: 'parameter',
    headerName: 'Parameter'
  },
  {
    field: 'instrument',
    headerName: 'Instrument'
  },
  {
    field: 'sampleName',
    headerName: 'Sample Name'
  },
  {
    field: 'dateTime',
    headerName: 'Date/Time'
  },
  {
    field: 'personnel',
    headerName: 'Personnel'
  },
  {
    field: 'sampleId',
    headerName: 'Sample ID (Result ID)'
  },
  {
    field: 'setupData',
    headerName: 'Setup Data'
  },
  {
    field: 'result',
    headerName: 'Result'
  }
];
