import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import './ProductSection.scss';
import Modal from 'react-bootstrap/Modal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../util/firebase';
import {
  useGetIndexValues,
  useGetProducts,
  useGetPersonnel,
  useGetSetupGroups
} from '../../util/db';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { useAuth } from '../../util/auth';
import CustomTable from '../Table/CustomTable';
import { RULES, SCREEN } from '../../util/Constant';
import {
  checkDelete,
  checkUpdate,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission
} from '../../util/util';
import moment from 'moment';
import { useCSVDownloader } from 'react-papaparse';

const functions = getFunctions(firebaseApp);
const updateSetupGroupsGenericFunction = httpsCallable(functions, 'updateSetupGroupsGeneric');

function ProductSection({ variation = '' }) {
  const auth = useAuth();

  const [currentProducts, setCurrentProducts] = useState([]);
  const { data: products } = useGetProducts(variation);
  const [productsToUpdate, setProductsToUpdate] = useState([]);
  const { data: personnel } = useGetPersonnel(variation);
  const [showModal, setShowModal] = useState(false);
  const [indexes, setIndexes] = useState([]);
  const [currentIndexes, setCurrentIndexes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const [permission, setPermission] = useState(null);
  const { data: setupGroups } = useGetSetupGroups(variation);
  const defaultProduct = {
    location: '',
    name: '',
    notes: '',
    recType: 'products'
  };
  const [currentModalProduct, setCurrentModalProduct] = useState(defaultProduct);
  const [actions, setActions] = useState(['none']);
  const [hasEdit, setHasEdit] = useState(false);

  const [csvData, setCSvData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    if (permission) {
      const value = [];
      if (checkDelete(permission, SCREEN.PRODUCT)) {
        value.push('delete');
      }
      if (checkUpdate(permission, SCREEN.PRODUCT)) {
        setHasEdit(checkUpdate(permission, SCREEN.PRODUCT));
        value.push('edit');
      }
      setActions(value);
    }
  }, [permission]);

  useEffect(() => {
    if (Array.isArray(setupGroups) && Array.isArray(products) && auth) {
      const setupGroupsAssigned = [];
      const productsToReturn = [];
      setupGroups.forEach((setupGroup) => {
        const matchingUser = setupGroup.personnel.find(
          (person) => person.email === auth.user.email
        );
        if (matchingUser) {
          setupGroupsAssigned.push(setupGroup);
        }
      });
      setupGroupsAssigned.forEach((assignedSetupGroup) => {
        if (Array.isArray(assignedSetupGroup.products)) {
          assignedSetupGroup.products.forEach((productAssigned) => {
            const existingProduct = products.find((product) => product.id === productAssigned);
            if (existingProduct) {
              productsToReturn.push(existingProduct);
            }
          });
        }
      });
      setCurrentProducts(productsToReturn);
    }
  }, [setupGroups, products, auth]);

  const saveProducts = () => {
    const currentProductsToUpdate = productsToUpdate;
    currentProductsToUpdate.forEach((product) => {
      updateSetupGroupsGenericFunction({
        updateType: 'update',
        name: 'product',
        theObject: product,
        variation
      });
    });
    setProductsToUpdate([]);
  };

  useEffect(() => {
    const updatedLocationsPersonnel = [];
    currentIndexes.forEach((index) => {
      const locationToPush = {
        id: index.name,
        name: index.name,
        value: index.name,
        label: index.name
      };
      updatedLocationsPersonnel.push(locationToPush);
    });
    setAllLocations(updatedLocationsPersonnel);
    console.log('allLocations', allLocations);
  }, [currentIndexes]);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
      setCurrentPersonnel(personnel);
    }
  }, [personnel]);

  useEffect(() => {
    const csvHeader = ['Location', 'Product Name', 'Notes 1'];

    const columns = ['location', 'name', 'notes'];

    const data = currentProducts.map((row) => {
      const object = csvHeader.map((c, index) => {
        const fieldName = columns[index] || undefined;

        if (fieldName === undefined) {
          return [c, ''];
        }

        return [c, row[fieldName]];
      });

      const entries = new Map([...object]);
      return Object.fromEntries(entries);
    });

    setCSvData(data);
  }, [currentProducts]);

  const renderLocationOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.name}>
          {option.name}
        </option>
      );
    });
    return rows;
  };

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0) {
      const activeIndexes = indexes.filter((index) => !index.id.includes('Archive'));
      if (activeIndexes.length > 0) {
        setCurrentIndexes(activeIndexes[0].index);
      }
    }
  }, [indexes]);

  useEffect(() => {
    (async () => {
      const result = await useGetIndexValues(variation);
      setIndexes(result.data);
    })();
  }, []);

  const dataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      editable: hasEdit,
      flex: 4,
      sortable: false,
      type: 'singleSelect',
      valueOptions: allLocations
    },
    {
      field: 'name',
      headerName: 'Product Name',
      editable: hasEdit,
      flex: 3,
      sortable: true
    },
    {
      field: 'notes',
      headerName: 'Notes',
      editable: hasEdit,
      flex: 2,
      sortable: false
    }
  ];

  const handleResults = (results) => {
    updateSetupGroupsGenericFunction({
      updateType: 'addList',
      name: 'product',
      variation,
      theObject: results
        .map((product) => {
          if (product[0] && product[0] !== 'Location') {
            return {
              location: product[0],
              name: product[1],
              notes: product[2],
              recType: 'products'
            };
          } else {
            return null;
          }
        })
        .filter(Boolean)
    });
  };

  const handleProductAdd = () => {
    updateSetupGroupsGenericFunction({
      updateType: 'add',
      theObject: currentModalProduct,
      variation,
      name: 'product'
    });
    setShowModal(false);
    setCurrentModalProduct(defaultProduct);
  };

  const renderOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.value}>
          {option.label}
        </option>
      );
    });
    return rows;
  };

  const handleProductChange = (e, propertyName) => {
    setCurrentModalProduct({
      ...currentModalProduct,
      [propertyName]: e.target.value
    });
  };

  const deleteRowData = (data) => {
    updateSetupGroupsGenericFunction({
      updateType: 'delete',
      theObject: data,
      variation,
      name: 'product'
    });
  };

  return (
    <div>
      <div className="header">
        <h2>Products</h2>
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="personnel-information-container">
        <CustomTable
          numberOfRows={10}
          data={currentProducts}
          header={dataEntryTableColumns}
          action={actions}
          updateRowData={(data) => {
            setProductsToUpdate([...productsToUpdate, data]);
          }}
          deleteRowData={(data) => {
            deleteRowData(data);
          }}
          sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
        />
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Product',
              () => setShowModal(true),
              SCREEN.PRODUCT,
              RULES.CREATE,
              permission
            )}
          </div>
          <div>
            <CSVDownloader
              product={Type.Button}
              className="btn btn-primary"
              filename={`Products - ${moment().format('YYYY-MM-DD')}.csv`}
              data={csvData}
            >
              Export CSV
            </CSVDownloader>
          </div>

          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permission,
            SCREEN.PRODUCT,
            RULES.CREATE
          )}
        </div>
        <div>
          {renderButtonWithPermissions(
            'Save data',
            () => saveProducts(),
            SCREEN.PRODUCT,
            RULES.CREATE,
            permission
          )}
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Select
                value={currentModalProduct.location}
                onChange={(e) => handleProductChange(e, 'location')}
                aria-label="Default select example"
              >
                <option value="">Assign a location</option>
                {renderLocationOptions(allLocations)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={currentModalProduct.name}
                onChange={(e) => handleProductChange(e, 'name')}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                value={currentModalProduct.notes}
                onChange={(e) => handleProductChange(e, 'notes')}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowModal(false)} />
          <PrimaryButton text="Save" clicked={() => handleProductAdd()} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductSection;
