import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getFunctions, httpsCallable } from 'firebase/functions';

import Alert from 'react-bootstrap/Alert';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { uniq, uniqBy } from 'lodash';
import './DataEntrySection.scss';
import { useAuth } from '../../util/auth';
import { textSort } from '../../util/util';
import {
  createSilencedData,
  useGetIndexValues,
  useGetInstruments,
  useGetMethods,
  useGetSamples,
  useGetTypes,
  useGetProducts,
  useGetCompanies,
  useGetCompanyType,
  useGetSilencedDataByShortKey,
  useGetScheduledSamples,
  deleteScheduledSample,
  createScheduledSamples
} from '../../util/db';
import firebaseApp, { getCurrentFeatureFlags } from '../../util/firebase';
import ChartContext from './DataEntryProvider';
import CustomTable from '../Table/CustomTable';
import { dayTimeComparator } from '../Columns';
import { RULES, SCREEN } from '../../util/Constant';
import {
  checkReadOnly,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission
} from '../../util/util';

const functions = getFunctions(firebaseApp);
const addMultipleDataFunction = httpsCallable(functions, 'addMultipleData', { timeout: 540000 });
const runMultipleDataFunction = httpsCallable(functions, 'runMultipleData', { timeout: 540000 });

function DataEntrySection({ variation = '' }) {
  const auth = useAuth();
  const currentTime = `${moment(Date.now()).format('MM/DD/YYYY')} 12:00 PM`;
  const [currentInstrumentsDropdown, setCurrentInstrumentsDropdown] = useState([]);
  const [currentLocations, setCurrentLocations] = useState([]);
  const { data: methods } = useGetMethods(variation);
  const { data: instruments } = useGetInstruments(variation);
  const { data: types } = useGetTypes(variation);
  const { data: products } = useGetProducts(variation);
  const { data: companyTypes } = useGetCompanyType(variation);
  const { data: companies } = useGetCompanies(variation);
  const [currentTypesDropdown, setCurrentTypesDropdown] = useState([]);
  const [currentProductsDropdown, setCurrentProductsDropdown] = useState([]);
  const [currentMethodsDropdown, setCurrentMethodsDropdown] = useState([]);
  const [currentSamplesDropdown, setCurrentSamplesDropdown] = useState([]);
  const { data: samples } = useGetSamples(variation);
  const [currentPersonnelDropdown, setCurrentPersonnelDropdown] = useState([]);
  const [indexes, setIndexes] = useState([]);
  const [radioValue, setRadioValue] = useState('1');
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showDataAlert, setShowDataAlert] = useState(false);
  const [recheckAlert, setRecheckAlert] = useState(false);
  const [addDataAlert, setAddDataAlert] = useState(false);
  const [currentSilencedData, setCurrentSilencedData] = useState([]);
  const [dataToRecheck, setDataToRecheck] = useState([]);
  const {
    currentChartGroupConfigurations,
    currentShortKey,
    chartData,
    permissionData,
    personnel,
    currentPersonnel,
    summaries
  } = useContext(ChartContext);
  const { data: scheduledSamples } = useGetScheduledSamples(variation);
  const { data: silencedData } = useGetSilencedDataByShortKey(currentShortKey, variation);
  const [currentScheduledSamples, setCurrentScheduledSamples] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [featureFlags, setFeatureFlags] = useState(getCurrentFeatureFlags);
  const [radios] = useState([
    { name: 'Scheduled & Recheck Data', value: '1' },
    { name: 'Silenced Data', value: '2' }
  ]);
  const [deleteList, setDeleteList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const silenceOptions = [
    {
      value: 'No',
      label: 'No'
    },
    {
      value: 'Yes',
      label: 'Yes'
    }
  ];
  const pageSizeDefault = 20;

  useEffect(() => {
    if (
      Array.isArray(scheduledSamples) &&
      scheduledSamples.length > 0 &&
      currentPersonnel.length > 0
    ) {
      const matchingUser = currentPersonnel.find((person) => person.email === auth.user.email);
      const additionalProperties = {
        personnel: matchingUser ? matchingUser.name : auth.user.email,
        setupData: 0,
        result: ''
      };

      const mappedSamples = scheduledSamples.map((sample) => {
        let ucl_lcl = {};
        if (true) {
          const findSummary = summaries.find((item) => {
            return item.id === sample.fullKey;
          });
          if (findSummary) {
            ucl_lcl = {
              lowerControlLimit: Math.round(findSummary.lcl_sd * 100) / 100,
              upperControlLimit: Math.round(findSummary.ucl_sd * 100) / 100
            };
          }
        }
        return {
          ...sample,
          ...additionalProperties,
          ...ucl_lcl
        };
      });
      setCurrentScheduledSamples(mappedSamples);
    }
  }, [scheduledSamples]);

  const handleRadioChange = (e) => {
    if (e.currentTarget.value === '2') {
      setRecheckAlert(false);
    } else if (dataToRecheck.length > 0) {
      setRecheckAlert(true);
    }

    setRadioValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (Array.isArray(silencedData) && silencedData.length > 0) {
      setCurrentSilencedData(silencedData);
    }
  }, [silencedData]);

  useEffect(() => {
    if (Array.isArray(samples) && samples.length > 0) {
      samples.sort((a, b) => String(a.name).localeCompare(String(b.name)));
      const samplesDropdown = samples.map((sample) => {
        const sampleToReturn = {
          id: sample.id,
          value: sample.name,
          label: sample.name
        };

        return sampleToReturn;
      });
      setCurrentSamplesDropdown(samplesDropdown);
    }
  }, [samples]);

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0) {
      const locations = [];
      const activeIndexes = indexes.filter((index) => !index.id.includes('Archive'));

      activeIndexes[0].index.forEach((location) => {
        const locationToReturn = {
          value: location.name,
          label: location.name
        };
        locations.push(locationToReturn);
      });

      // we also want to pull from configuration locations
      // (note that we may want to restrict this on a per-user basis)
      if (Array.isArray(companies) && companies.length > 0) {
        companies.forEach((company) => {
          const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;
          const locationToPush = {
            value: currentLocation,
            label: currentLocation
          };
          if (!locations.find((location) => location.value === currentLocation)) {
            locations.push(locationToPush);
          }
        });
      }

      setCurrentLocations(locations);
    }
  }, [indexes]);

  useEffect(() => {
    (async () => {
      const result = await useGetIndexValues(variation);
      setIndexes(result.data);
    })();
  }, []);

  useEffect(() => {
    const updatedDataToRecheck = [];
    const oocDataToRecheck = structuredClone(chartData).filter((dataPoint) =>
      dataPoint.flags.includes('OOC')
    );
    oocDataToRecheck.forEach((dataPoint) => {
      if (typeof dataPoint.sampleId === 'string' && !dataPoint.sampleId.includes('_recheck')) {
        const newDataPoint = { ...dataPoint };
        newDataPoint.sampleId += '_recheck';
        // add data point with same data but updated sample ID
        newDataPoint.flags = '';
        newDataPoint.silenceReason = '';
        newDataPoint.result = '';
        updatedDataToRecheck.push(newDataPoint);
      }
    });
    setDataToRecheck(updatedDataToRecheck);
  }, [chartData]);

  useEffect(() => {
    if (currentChartGroupConfigurations.autoCreateSampleRecheck === 'Yes') {
      setRecheckAlert(true);
    } else {
      setRecheckAlert(false);
    }
  }, [currentChartGroupConfigurations.autoCreateSampleRecheck]);

  const getDropdown = (array) => {
    if (Array.isArray(array) && array.length > 0) {
      array.sort((a, b) => {
        const nameA = a.name || '';
        const nameB = b.name || '';
        return String(nameA).localeCompare(String(nameB));
      });
    }
    const uniques = uniqBy(array, (item) => item.name) || [];
    const dropdown = uniques.map((item) => {
      const itemToReturn = {
        id: item.id,
        value: item.name,
        label: item.name
      };
      return itemToReturn;
    });
    return dropdown;
  };

  useEffect(() => {
    setCurrentTypesDropdown(getDropdown(types));
  }, [types]);

  useEffect(() => {
    setCurrentProductsDropdown(getDropdown(products));
  }, [products]);

  useEffect(() => {
    setCurrentPersonnelDropdown(getDropdown(personnel));
  }, [personnel]);

  useEffect(() => {
    setCurrentInstrumentsDropdown(getDropdown(instruments));
  }, [instruments]);

  const renderAlert = (renderRecheckAlert) => {
    if (renderRecheckAlert) {
      return (
        <Alert key="warning" onClick={() => setShow(false)} dismissible variant="warning">
          You have {dataToRecheck.length} samples that have been flagged for recheck.
        </Alert>
      );
    }
    return <></>;
  };

  const renderDataSuccessAlert = (dataAlert) => {
    if (dataAlert) {
      return (
        <Alert key="success" onClick={() => setAddDataAlert(false)} dismissible variant="success">
          Successfully added data
        </Alert>
      );
    }
    return <></>;
  };

  const renderDropdownParameter = (data, key) => {
    const methodSelect = data.row.method;
    const parameterFilter = methods
      .filter((item) => item.method === methodSelect)
      .map((value) => {
        return {
          value: value[key],
          label: value[key]
        };
      });

    const parameterFilterUniq = uniqBy(parameterFilter, (item) => item.label);
    parameterFilterUniq.sort(textSort);
    return parameterFilterUniq;
  };

  const deleteRow = (rowIn) => {
    const row = rowIn;
    setDeleteList([...deleteList, rowIn]);
    setCurrentScheduledSamples(currentScheduledSamples.filter((item) => item.id !== row.id));
  };

  const [dateTimeChanged, setDateTimeChanged] = useState([]);

  const dataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      editable: true,
      type: 'singleSelect',
      valueOptions: currentLocations,
      flex: 4
    },
    {
      field: variation === 'spc' ? 'type' : 'method',
      headerName: variation === 'spc' ? 'Type' : 'Method',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentTypesDropdown : currentMethodsDropdown,
      flex: 2
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      editable: true,
      type: 'singleSelect',
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      flex: 2
    },
    {
      field: variation === 'spc' ? 'method' : 'instrument',
      headerName: variation === 'spc' ? 'Method' : 'Instrument',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentMethodsDropdown : currentInstrumentsDropdown,
      flex: 2
    },
    {
      field: variation === 'spc' ? 'product' : 'sampleName',
      headerName: variation === 'spc' ? 'Product' : 'Sample Name',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentProductsDropdown : currentSamplesDropdown,
      flex: 2
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      editable: true,
      flex: 3,
      valueGetter: (params) => {
        return moment(params.value).format('MM/DD/YYYY hh:mm A');
      }
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      editable: true,
      type: 'singleSelect',
      flex: 3,
      valueOptions: currentPersonnelDropdown
    },
    {
      field: 'sampleId',
      editable: true,
      headerName: 'Sample ID',
      type: 'string',
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const huntTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      editable: true,
      type: 'singleSelect',
      valueOptions: currentLocations,
      flex: 4
    },
    {
      field: variation === 'spc' ? 'type' : 'method',
      headerName: variation === 'spc' ? 'Type' : 'Method',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentTypesDropdown : currentMethodsDropdown,
      flex: 2
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      editable: true,
      type: 'singleSelect',
      flex: 2
    },
    {
      field: variation === 'spc' ? 'method' : 'instrument',
      headerName: variation === 'spc' ? 'Method' : 'Instrument',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentMethodsDropdown : currentInstrumentsDropdown,
      flex: 2
    },
    {
      field: variation === 'spc' ? 'product' : 'sampleName',
      headerName: variation === 'spc' ? 'Product' : 'Sample Name',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentProductsDropdown : currentSamplesDropdown,
      flex: 2
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      editable: true,
      flex: 3
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      valueOptions: currentPersonnelDropdown,
      editable: true,
      type: 'singleSelect',
      flex: 3
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      type: 'string',
      editable: true,
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'lowerControlLimit',
      headerName: 'LCL',
      editable: true,
      flex: 2
    },
    {
      field: 'upperControlLimit',
      headerName: 'UCL',
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, rowIn, column) => {
      const row = rowIn;
      row[column.dataField] = newValue;
    }
  };

  const renderScheduledDataTable = (instanceFeatureFlags) => {
    const tableToReturn = [];
    if (instanceFeatureFlags.includes('lcl-data-entry-columns')) {
      tableToReturn.push(
        <>
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={currentScheduledSamples}
            setData={setCurrentScheduledSamples}
            action={['delete', 'edit']}
            header={huntTableColumns}
            deleteRowData={deleteRow}
          />
        </>
      );
    } else {
      tableToReturn.push(
        <>
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={currentScheduledSamples}
            setData={setCurrentScheduledSamples}
            header={dataEntryTableColumns}
            action={['delete', 'edit']}
            deleteRowData={deleteRow}
            onCellClickWhenEditing={(params, event) => {
              if (params.field === 'dateTime') {
                setDateTimeChanged([...dateTimeChanged, params.row.id]);
              }
            }}
          />
        </>
      );
    }
    return tableToReturn;
  };

  const dataEntryTableColumnsReadOnly = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 2,
      minWidth: 300
    },
    {
      field: 'method',
      headerName: 'Method',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'sampleName',
      headerName: 'Sample Name',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'result',
      headerName: 'Result',
      flex: 1,
      minWidth: 150
    }
  ];

  const addRow = () => {
    const matchingUser = currentPersonnel.find((person) => person.email === auth.user.email);
    const rowToAdd = {
      id: uuidv4(),
      location: '',
      method: '',
      type: '',
      product: '',
      parameter: '',
      instrument: '',
      sampleName: '',
      dateTime: currentTime,
      personnel: matchingUser ? matchingUser.name : auth.user.email,
      sampleId: '',
      result: '',
      setupData: 0,
      delete: '',
      isNew: true
    };
    setCurrentScheduledSamples([...currentScheduledSamples, rowToAdd]);
  };

  const recheckDataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 4,
      valueOptions: currentLocations,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: variation === 'spc' ? 'type' : 'method',
      headerName: variation === 'spc' ? 'Type' : 'Method',
      flex: 2,
      valueOptions: variation === 'spc' ? currentTypesDropdown : currentMethodsDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 2,
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      editable: true,
      type: 'singleSelect'
    },
    {
      field: variation === 'spc' ? 'method' : 'instrument',
      headerName: variation === 'spc' ? 'Method' : 'Instrument',
      flex: 2,
      valueOptions: variation === 'spc' ? currentMethodsDropdown : currentInstrumentsDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: variation === 'spc' ? 'product' : 'sampleName',
      headerName: variation === 'spc' ? 'Product' : 'Sample Name',
      flex: 2,
      valueOptions: variation === 'spc' ? currentProductsDropdown : currentSamplesDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'dateTime',
      editable: true,
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      flex: 3
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 3,
      valueOptions: currentPersonnelDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      editable: true,
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'silence',
      headerName: 'Silence?',
      flex: 2,
      valueOptions: silenceOptions,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'silenceReason',
      headerName: 'Reason for Silencing',
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const recheckDataEntryTableColumnsReadOnly = [
    {
      field: 'location',
      flex: 4,
      headerName: 'Location'
    },
    {
      field: variation === 'spc' ? 'type' : 'method',
      flex: 2,
      headerName: variation === 'spc' ? 'Type' : 'Method'
    },
    {
      field: 'parameter',
      flex: 2,
      headerName: 'Parameter'
    },
    {
      field: variation === 'spc' ? 'method' : 'instrument',
      flex: 2,
      headerName: variation === 'spc' ? 'Method' : 'Instrument'
    },
    {
      field: variation === 'spc' ? 'product' : 'sampleName',
      flex: 2,
      headerName: variation === 'spc' ? 'Product' : 'Sample Name'
    },
    {
      field: 'dateTime',
      flex: 3,
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator
    },
    {
      field: 'personnel',
      flex: 2,
      headerName: 'Personnel'
    },
    {
      field: 'sampleId',
      flex: 3,
      headerName: 'Sample ID'
    },
    {
      field: 'setupData',
      flex: 2,
      headerName: 'Setup Data'
    },
    {
      field: 'silence',
      flex: 2,
      headerName: 'Silence?'
    },
    {
      field: 'silenceReason',
      flex: 2,
      headerName: 'Reason for Silencing'
    },
    {
      field: 'result',
      flex: 2,
      headerName: 'Result'
    }
  ];

  const silencedDataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 2,
      valueOptions: currentLocations,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: variation === 'spc' ? 'type' : 'method',
      headerName: variation === 'spc' ? 'Type' : 'Method',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentTypesDropdown : currentMethodsDropdown,
      flex: 2
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 2,
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      editable: true,
      type: 'singleSelect'
    },
    {
      field: variation === 'spc' ? 'method' : 'instrument',
      headerName: variation === 'spc' ? 'Method' : 'Instrument',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentMethodsDropdown : currentInstrumentsDropdown,
      flex: 2
    },
    {
      field: variation === 'spc' ? 'product' : 'sampleName',
      headerName: variation === 'spc' ? 'Product' : 'Sample Name',
      editable: true,
      type: 'singleSelect',
      valueOptions: variation === 'spc' ? currentProductsDropdown : currentSamplesDropdown,
      flex: 2
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      editable: true,
      flex: 2
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 2,
      valueOptions: currentPersonnelDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      editable: true,
      flex: 2
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'silence',
      headerName: 'Silence?',
      editable: true,
      flex: 2
    },
    {
      field: 'silenceReason',
      headerName: 'Reason for Silencing',
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const silencedDataEntryTableColumnsReadOnly = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 4
    },
    {
      field: variation === 'spc' ? 'type' : 'method',
      headerName: variation === 'spc' ? 'Type' : 'Method',
      flex: 2
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 2
    },
    {
      field: variation === 'spc' ? 'method' : 'instrument',
      headerName: variation === 'spc' ? 'Method' : 'Instrument',
      flex: 2
    },
    {
      field: variation === 'spc' ? 'product' : 'sampleName',
      headerName: variation === 'spc' ? 'Product' : 'Sample Name',
      flex: 2
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      flex: 3
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 3
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      flex: 2
    },
    {
      field: 'silence',
      headerName: 'Silence?',
      flex: 2
    },
    {
      field: 'silenceReason',
      headerName: 'Reason for Silencing',
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      flex: 2
    }
  ];

  const renderDataTableSection = (renderRadioValue) => {
    if (renderRadioValue === '1') {
      if (!recheckAlert) {
        return (
          <>
            <div className="overview-chart-header">
              <h4>Scheduled Data</h4>
            </div>
            {renderDataSuccessAlert(addDataAlert)}
            {renderScheduledDataTable(featureFlags)}
          </>
        );
      }
      return (
        <>
          <div className="overview-chart-header">
            <h4>Recheck Data</h4>
          </div>
          {renderAlert(recheckAlert)}
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={dataToRecheck}
            header={recheckDataEntryTableColumns}
            action={['delete', 'edit']}
            deleteRowData={deleteRow}
          />
          <div className="overview-chart-header">
            <h4>Scheduled Data</h4>
          </div>
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={currentScheduledSamples}
            setData={setCurrentScheduledSamples}
            header={dataEntryTableColumns}
            action={['delete', 'edit']}
            deleteRowData={deleteRow}
          />
        </>
      );
    }
    return (
      <>
        <div className="overview-chart-header">
          <h4>Silenced Data</h4>
        </div>
        <CustomTable
          numberOfRows={pageSizeDefault}
          data={currentSilencedData}
          header={silencedDataEntryTableColumns}
          action={['delete', 'edit']}
          deleteRowData={deleteRow}
        />
      </>
    );
  };

  const renderDataTableReadOnly = (renderRadioValue) => {
    if (renderRadioValue === '1') {
      if (!recheckAlert) {
        return (
          <>
            <div className="overview-chart-header">
              <h4>Scheduled Data</h4>
            </div>
            <CustomTable
              data={currentScheduledSamples}
              header={dataEntryTableColumnsReadOnly}
              numberOfRows={pageSizeDefault}
            />
          </>
        );
      }
      return (
        <>
          <div className="overview-chart-header">
            <h4>Recheck Data</h4>
          </div>
          {renderAlert(recheckAlert)}
          <CustomTable
            data={dataToRecheck}
            header={recheckDataEntryTableColumnsReadOnly}
            numberOfRows={pageSizeDefault}
          />
          <div className="overview-chart-header">
            <h4>Scheduled Data</h4>
          </div>
          <CustomTable
            data={currentScheduledSamples}
            header={dataEntryTableColumnsReadOnly}
            numberOfRows={pageSizeDefault}
          />
        </>
      );
    }
    return (
      <>
        <div className="overview-chart-header">
          <h4>Silenced Data</h4>
        </div>
        <CustomTable
          data={currentSilencedData}
          header={silencedDataEntryTableColumnsReadOnly}
          numberOfRows={pageSizeDefault}
        />
      </>
    );
  };

  const renderTablesWithPermissions = (renderRadioValue) => {
    const readOnly = checkReadOnly(permissionData, SCREEN.DATA_ENTRY);
    if (readOnly) {
      return renderDataTableReadOnly(renderRadioValue);
    }
    return renderDataTableSection(renderRadioValue);
  };

  useEffect(() => {
    if (Array.isArray(methods) && methods.length > 0) {
      const methodsDropdown = [];
      methods.forEach((method) => {
        const methodToReturn = {
          id: method.id,
          value: `${method.method}`,
          label: `${method.method}`
        };
        const parameterToReturn = {
          value: method.parameter,
          label: method.parameter
        };
        if (!methodsDropdown.find((dropdownItem) => dropdownItem.value === `${method.method}`)) {
          methodsDropdown.push(methodToReturn);
        }
      });
      methodsDropdown.sort(textSort);
      setCurrentMethodsDropdown(methodsDropdown);
    }
  }, [methods]);

  // eslint-disable-next-line no-unused-vars
  const [columnToDisplay, setColumnToDisplay] = useState(dataEntryTableColumns);

  const deleteDataEntry = () => {
    if (deleteList.length > 0) {
      deleteList.forEach((item) => {
        deleteScheduledSample(item.id, variation);
      });
      setDeleteList([]);
    }
  };

  const saveParsedData = async (currentRowsToSave) => {
    setLoading(true);
    const addDataObject = {
      rows: currentRowsToSave,
      variation,
      isImportFromFile: true
    };
    const response = await addMultipleDataFunction(addDataObject);
    if (response.data && response.data.status === 200) {
      const runDataObject = {
        variation,
        listParameter: currentRowsToSave.map((r) => ({
          location: r.location,
          method: r.method,
          instrument: variation === 'spc' ? null : r.instrument,
          sampleName: variation === 'spc' ? null : r.sampleName,
          type: variation === 'spc' ? r.type : null,
          product: variation === 'spc' ? r.product : null
        }))
      };
      const response = await runMultipleDataFunction(runDataObject);
      if (response.data && response.data.status === 200) {
        setAddDataAlert(true);
      }
    }

    setLoading(false);
    setCurrentScheduledSamples([]);
  };

  const saveData = async (currentRowsToSave) => {
    const parsedRowsToSave = currentRowsToSave.filter((row) => row.result !== '');
    if (recheckAlert) {
      parsedRowsToSave.forEach((row) => {
        if (row.silence === 'Yes') {
          createSilencedData(row, variation);
        }
      });
    } else {
      setLoading(true);

      deleteDataEntry();

      const columnsToCheck =
        variation === 'spc'
          ? ['location', 'type', 'product', 'method', 'parameter']
          : ['location', 'method', 'sampleName', 'instrument', 'parameter'];

      const currentRowsToSave = parsedRowsToSave
        .map((rowIn) => ({
          ...rowIn,
          dateTime: moment(rowIn.dateTime, ['MM/DD/YYYY hh:mm A', 'M/D/YYYY hh:mm A']).toISOString()
        }))
        .filter((row) => {
          for (const column of columnsToCheck) {
            if (row[column] === '') {
              return false;
            }
          }
          return true;
        });
      const rowsToAddNew = currentRowsToSave
        .filter((row) => !!row.isNew)
        .map((item) => {
          let fullKey;
          if (variation === 'spc') {
            fullKey = `${item.location}|${item.type}|${item.product}|${item.method}|${item.parameter}`;
          } else {
            fullKey = `${item.location}|${item.method}|${item.sampleName}|${item.instrument}|${item.parameter}`;
          }
          return {
            dateTime: dateTimeChanged.includes(item.id) ? item.dateTime : moment().toISOString(),
            fullKey,
            location: item.location,
            method: item.method,
            parameter: item.parameter,
            instrument: variation === 'spc' ? null : item.instrument,
            sampleName: variation === 'spc' ? null : item.sampleName,
            type: variation === 'spc' ? item.type : null,
            product: variation === 'spc' ? item.product : null
          };
        });

      if (currentRowsToSave.length === 0) {
        setLoading(false);
        return;
      }

      const addDataObject = {
        variation,
        rows: currentRowsToSave.map((item) => {
          delete item.isNew;
          delete item.delete;
          if (!item.lowerControlLimit) {
            delete item.lowerControlLimit;
          }
          if (!item.upperControlLimit) {
            delete item.upperControlLimit;
          }
          return {
            ...item,
            dateTime: dateTimeChanged.includes(item.id) ? item.dateTime : moment().toISOString()
          };
        })
      };
      const response = await addMultipleDataFunction(addDataObject);
      if (response.data && response.data.status === 200) {
        const runDataObject = {
          variation,
          listParameter: currentRowsToSave.map((r) => ({
            location: r.location,
            method: r.method,
            instrument: variation === 'spc' ? null : r.instrument,
            sampleName: variation === 'spc' ? null : r.sampleName,
            type: variation === 'spc' ? r.type : null,
            product: variation === 'spc' ? r.product : null
          }))
        };
        const response = await runMultipleDataFunction(runDataObject);
        if (response.data && response.data.status === 200) {
          rowsToAddNew.forEach((row) => {
            createScheduledSamples(row, variation);
          });

          currentRowsToSave.forEach((row) => {
            deleteScheduledSample(row.id, variation);
          });
          setAddDataAlert(true);
        }
      }

      setLoading(false);
    }
  };

  const handleResults = async (results) => {
    const currentRowsToUpdate = results
      .map((entry, index) => {
        if (index > 0 && entry[0]) {
          if (variation === 'spc') {
            return {
              location: entry[0],
              type: entry[1],
              parameter: entry[2],
              method: entry[3],
              product: entry[4],
              dateTime: moment(entry[5], ['MM/DD/YYYY hh:mm', 'M/D/YYYY hh:mm']).toISOString(),
              personnel: entry[6],
              sampleId: entry[7],
              setupData: entry[8],
              result: entry[9],
              delete: ''
            };
          }
          return {
            location: entry[0],
            method: entry[1],
            parameter: entry[2],
            instrument: entry[3],
            sampleName: entry[4],
            dateTime: moment(entry[5], ['MM/DD/YYYY hh:mm', 'M/D/YYYY hh:mm']).toISOString(),
            personnel: entry[6],
            sampleId: entry[7],
            setupData: entry[8],
            result: entry[9],
            delete: ''
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);

    await saveParsedData(currentRowsToUpdate);
  };

  return (
    <div>
      <div className="header-button-container">
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={radio.value}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={handleRadioChange}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
      <div className="data-entry-container">
        <Col>{renderTablesWithPermissions(radioValue)}</Col>
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Row',
              () => addRow(),
              SCREEN.DATA_ENTRY,
              RULES.CREATE,
              permissionData
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permissionData,
            SCREEN.DATA_ENTRY,
            RULES.CREATE
          )}
        </div>
        <div>
          {renderButtonWithPermissions(
            'Save Data',
            () => saveData(currentScheduledSamples),
            SCREEN.DATA_ENTRY,
            RULES.SAVE,
            permissionData,
            true,
            isLoading
          )}
        </div>
        {/* <ToastComponent /> */}
      </div>
    </div>
  );
}

export default DataEntrySection;
