import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { uniq } from 'lodash';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import './DashboardSection.scss';
import Section from './Section';
import { useRouter } from '../util/router';
import { useAuth } from '../util/auth';
import { useGetCompanyType, useGetIndexValues, useGetSummaries } from '../util/db';
import CustomTable from './Table/CustomTable';
import SQCPieChart from '../components/Common/chart/SQCPieChart';
import { archiveRegex } from '../util/Constant';
import { SQCBarChart } from './Common/chart/SQCBarChart';

function DashboardSection({ bg, textColor, bgImage, bgImageOpacity }) {
  const auth = useAuth();
  const router = useRouter();
  const [currentSummaries, setCurrentSummaries] = useState([]);
  const [currentSummariesTpi, setCurrentSummariesTpi] = useState([]);
  const [allSummaries, setAllSummaries] = useState([]);
  const { data: rawSummaries } = useGetSummaries();
  const [indexes, setIndexes] = useState([]);
  const { data: companyTypes } = useGetCompanyType();
  const [allLocations, setAllLocations] = useState([]);
  const [currentIndexes, setCurrentIndexes] = useState([]);
  const [allDataComplete, setAllDataComplete] = useState(false);
  const [currentLocation] = useState(null);
  const [statusPieChartData, setStatusPieChartData] = useState([]);
  const [tpiPieChartData, setTpiPieChartData] = useState([]);
  const [warningPieChartData, setWarningPieChartData] = useState([]);
  const [oocPieChartData, setOocPieChartData] = useState([]);
  const [currentSummariesOOC, setCurrentSummariesOOC] = useState([]);
  const [currentSummariesWarning, setCurrentSummariesWarning] = useState([]);

  const updateStatusPieChart = (pieSummaries) => {
    const updatedChartStatuses = [];
    pieSummaries.forEach((summary) => {
      updatedChartStatuses.push(summary.chartStatus);
    });
    const uniqueChartStatuses = uniq(updatedChartStatuses);
    const mappedChartStatuses = [];
    uniqueChartStatuses.forEach((uniqueChartStatus) => {
      const mappedChartStatus = {
        name: uniqueChartStatus,
        value: updatedChartStatuses.filter((chartStatus) => chartStatus === uniqueChartStatus)
          .length
      };
      mappedChartStatuses.push(mappedChartStatus);
    });
    setStatusPieChartData(mappedChartStatuses);
  };

  const warningFlagForNonUs = (warningMap, summary, isNonUs) => {
    const instrument = summary.instrument;
    if (instrument && summary.parameter && summary.location) {
      const warningValue = summary.warnings_or;
      if (warningValue) {
        if (warningMap.has(instrument)) {
          warningMap.set(instrument, warningMap.get(instrument) + 1);
        } else {
          warningMap.set(instrument, 1);
        }
      }
    }
  };

  const warningFlagForUs = (warningMap, summary) => {
    const method = summary.method;
    if (method && summary.parameter && summary.location) {
      const warningValue = summary.warnings_or;
      if (warningValue) {
        if (warningMap.has(method)) {
          warningMap.set(method, warningMap.get(method) + 1);
        } else {
          warningMap.set(method, 1);
        }
      }
    }
  };
  const oocFlagForNonUs = (oocMap, summary) => {
    const instrument = summary.instrument;
    if (instrument && summary.parameter && summary.location) {
      const oocValue = summary.ooc_or;
      if (oocValue) {
        if (oocMap.has(instrument)) {
          oocMap.set(instrument, oocMap.get(instrument) + 1);
        } else {
          oocMap.set(instrument, 1);
        }
      }
    }
  };
  const oocFlagForUs = (oocMap, summary) => {
    const method = summary.method;
    if (method && summary.parameter && summary.location) {
      const oocValue = summary.ooc_or;
      if (oocValue) {
        if (oocMap.has(method)) {
          oocMap.set(method, oocMap.get(method) + 1);
        } else {
          oocMap.set(method, 1);
        }
      }
    }
  };

  const handleLocationChange = (e) => {
    let updatedCurrentSummaries = [];
    if (e.target.value !== '') {
      updatedCurrentSummaries = [...allSummaries].filter(
        (summary) => summary.location === e.target.value
      );
    } else {
      updatedCurrentSummaries = allSummaries;
    }
    updateStatusPieChart(updatedCurrentSummaries);
    setCurrentSummaries(updatedCurrentSummaries);
  };

  const handleLocationOOCChange = (e) => {
    const oocMap = new Map();
    let updatedCurrentSummaries = [];
    if (e.target.value !== '') {
      updatedCurrentSummaries = [...allSummaries].filter(
        (summary) => summary.location === e.target.value
      );
    } else {
      updatedCurrentSummaries = allSummaries;
    }
    updatedCurrentSummaries.forEach((summary) => {
      isNonUs ? oocFlagForNonUs(oocMap, summary) : oocFlagForUs(oocMap, summary);
    });
    setCurrentSummariesOOC(updatedCurrentSummaries.filter((item) => item.ooc_or));
    setOocPieChartData(Array.from(oocMap, ([name, value]) => ({ name, value })));
  };

  const handleLocationWarningChange = (e) => {
    const warningMap = new Map();
    let updatedCurrentSummaries = [];
    if (e.target.value !== '') {
      updatedCurrentSummaries = [...allSummaries].filter(
        (summary) => summary.location === e.target.value
      );
    } else {
      updatedCurrentSummaries = allSummaries;
    }
    updatedCurrentSummaries.forEach((summary) => {
      isNonUs ? warningFlagForNonUs(warningMap, summary) : warningFlagForUs(warningMap, summary);
    });
    setCurrentSummariesWarning(updatedCurrentSummaries.filter((item) => item.warnings_or));
    setWarningPieChartData(Array.from(warningMap, ([name, value]) => ({ name, value })));
  };

  const handleLocationChangeTpi = (e) => {
    let updatedCurrentSummaries = [];
    if (e.target.value !== '') {
      updatedCurrentSummaries = [...allSummaries].filter(
        (summary) => summary.location === e.target.value
      );
    } else {
      updatedCurrentSummaries = allSummaries;
    }
    const tpiValues = [];
    updatedCurrentSummaries.forEach((summary) => {
      tpiValues.push(summary.tpi_or);
    });
    const mappedTpiValues = [
      {
        name: '0.0 - 0.6',
        value: 0
      },
      {
        name: '0.61 - 1.0',
        value: 0
      },
      {
        name: '1.01 - 1.2',
        value: 0
      },
      {
        name: '1.21 - 2.0',
        value: 0
      },
      {
        name: '> 2.0',
        value: 0
      }
    ];
    tpiValues.forEach((value) => {
      const currentTpiValue = Number(value);
      if (currentTpiValue <= 0.6) {
        const indexToIncrement = mappedTpiValues.findIndex(
          (tpiObject) => tpiObject.name === '0.0 - 0.6'
        );
        mappedTpiValues[indexToIncrement].value += 1;
      }
      if (currentTpiValue > 0.6 && currentTpiValue <= 1) {
        const indexToIncrement = mappedTpiValues.findIndex(
          (tpiObject) => tpiObject.name === '0.61 - 1.0'
        );
        mappedTpiValues[indexToIncrement].value += 1;
      }
      if (currentTpiValue > 1.01 && currentTpiValue < 1.21) {
        const indexToIncrement = mappedTpiValues.findIndex(
          (tpiObject) => tpiObject.name === '1.01 - 1.2'
        );
        mappedTpiValues[indexToIncrement].value += 1;
      }
      if (currentTpiValue >= 1.21 && currentTpiValue <= 2.0) {
        const indexToIncrement = mappedTpiValues.findIndex(
          (tpiObject) => tpiObject.name === '1.21 - 2.0'
        );
        mappedTpiValues[indexToIncrement].value += 1;
      }
      if (currentTpiValue > 2.0) {
        const indexToIncrement = mappedTpiValues.findIndex(
          (tpiObject) => tpiObject.name === '> 2.0'
        );
        mappedTpiValues[indexToIncrement].value += 1;
      }
    });
    setCurrentSummariesTpi(updatedCurrentSummaries);
    setTpiPieChartData(mappedTpiValues);
  };

  const renderOptionsDynamic = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(<option value={option.name}>{option.name}</option>);
    });
    return rows;
  };

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0) {
      setCurrentIndexes(indexes);
    }
  }, [indexes]);

  useEffect(() => {
    (async () => {
      const result = await useGetIndexValues();
      setIndexes(result.data);
    })();
  }, []);

  useEffect(() => {
    if (Array.isArray(currentIndexes) && currentIndexes.length > 0 && !allDataComplete) {
      const activeIndexes = indexes.filter((index) => !index.id.includes('Archive'));
      const updatedLocations = [];
      activeIndexes[0].index.forEach((location) => {
        updatedLocations.push(location);
      });
      setAllLocations(updatedLocations);
      setAllDataComplete(true);
    }
  }, [currentIndexes]);

  const aggregateFlags = (summaryIn) => {
    const summary = summaryIn;
    summary.flags = '';
    summary.chartStatus = 'In control';
    if (summary.ooc_or && summary.warnings_or) {
      if (summary.ooc_or.length > 0 && summary.warnings_or.length === 0) {
        summary.flags = `OOC: ${summary.ooc_or}`;
        summary.chartStatus = 'OOC';
      } else if (summary.warnings_or.length > 0 && summary.ooc_or.length === 0) {
        summary.flags = `Warning: ${summary.warnings_or}`;
        summary.chartStatus = 'Warning';
      } else if (summary.warnings_or.length > 0 && summary.ooc_or.length > 0) {
        summary.flags = `OOC: ${summary.ooc_or}, Warning: ${summary.warnings_or}`;
        summary.chartStatus = 'OOC';
      }
    }
    return summary;
  };

  useEffect(() => {
    const summaries = Array.isArray(rawSummaries)
      ? rawSummaries.filter((summary) => !archiveRegex.test(summary.id))
      : [];
    if (summaries.length > 0) {
      const chartStatuses = [];
      const tpiValues = [];
      const oocMap = new Map();
      const warningMap = new Map();
      for (const summary of summaries) {
        summary.recent_sample = parseFloat(summary.recent_sample).toFixed(2);
        summary.tpi_or = parseFloat(summary.tpi_or).toFixed(2);
        summary.sp_or = parseFloat(summary.sp_or).toFixed(2);
        summary.avg_or = parseFloat(summary.avg_or).toFixed(2);
        if (parseInt(summary.n_or, 10) < 20) {
          summary.chartStage = 'In Development';
        } else if (parseInt(summary.n_or, 10) >= 20) {
          if (summary.assessed === undefined) {
            summary.chartStage = 'Needs Assessment';
          } else {
            summary.chartStage = 'Active';
          }
        }
        aggregateFlags(summary);
        chartStatuses.push(summary.chartStatus);
        tpiValues.push(summary.tpi_or);
        const fullKey = summary.id;
        summary.location = fullKey.substr(0, fullKey.indexOf('|'));
        const secondStr = fullKey.substr(fullKey.indexOf('|') + 1, fullKey.length - 1);
        summary.method = secondStr.substr(0, secondStr.indexOf('|'));
        const thirdStr = secondStr.substr(secondStr.indexOf('|') + 1, secondStr.length - 1);
        summary.sampleName = thirdStr.substr(0, thirdStr.indexOf('|'));
        const fourthStr = thirdStr.substr(thirdStr.indexOf('|') + 1, thirdStr.length - 1);
        summary.instrument = fourthStr.substr(0, fourthStr.indexOf('|'));
        const fifthStr = fourthStr.substr(fourthStr.indexOf('|') + 1, fourthStr.length - 1);
        summary.parameter = fifthStr.substr(0, fifthStr.length);

        isNonUs ? warningFlagForNonUs(warningMap, summary) : warningFlagForUs(warningMap, summary);
        isNonUs ? oocFlagForNonUs(oocMap, summary) : oocFlagForUs(oocMap, summary);
      }
      const uniqueChartStatuses = uniq(chartStatuses);
      const mappedChartStatuses = [];
      uniqueChartStatuses.forEach((uniqueChartStatus) => {
        const mappedChartStatus = {
          name: uniqueChartStatus,
          value: chartStatuses.filter((chartStatus) => chartStatus === uniqueChartStatus).length
        };
        mappedChartStatuses.push(mappedChartStatus);
      });
      const mappedTpiValues = [
        {
          name: '0.0 - 0.6',
          value: 0
        },
        {
          name: '0.61 - 1.0',
          value: 0
        },
        {
          name: '1.01 - 1.2',
          value: 0
        },
        {
          name: '1.21 - 2.0',
          value: 0
        },
        {
          name: '> 2.0',
          value: 0
        }
      ];
      tpiValues.forEach((value) => {
        const currentTpiValue = Number(value);
        if (currentTpiValue <= 0.6) {
          const indexToIncrement = mappedTpiValues.findIndex(
            (tpiObject) => tpiObject.name === '0.0 - 0.6'
          );
          mappedTpiValues[indexToIncrement].value += 1;
        }
        if (currentTpiValue > 0.6 && currentTpiValue <= 1) {
          const indexToIncrement = mappedTpiValues.findIndex(
            (tpiObject) => tpiObject.name === '0.61 - 1.0'
          );
          mappedTpiValues[indexToIncrement].value += 1;
        }
        if (currentTpiValue > 1.01 && currentTpiValue < 1.21) {
          const indexToIncrement = mappedTpiValues.findIndex(
            (tpiObject) => tpiObject.name === '1.01 - 1.2'
          );
          mappedTpiValues[indexToIncrement].value += 1;
        }
        if (currentTpiValue >= 1.21 && currentTpiValue <= 2.0) {
          const indexToIncrement = mappedTpiValues.findIndex(
            (tpiObject) => tpiObject.name === '1.21 - 2.0'
          );
          mappedTpiValues[indexToIncrement].value += 1;
        }
        if (currentTpiValue > 2.0) {
          const indexToIncrement = mappedTpiValues.findIndex(
            (tpiObject) => tpiObject.name === '> 2.0'
          );
          mappedTpiValues[indexToIncrement].value += 1;
        }
      });

      setCurrentSummaries(summaries);
      setCurrentSummariesTpi(summaries);
      setAllSummaries(summaries);
      setCurrentSummariesWarning(summaries.filter((item) => item.warnings_or));
      setCurrentSummariesOOC(summaries.filter((item) => item.ooc_or));
      setStatusPieChartData(mappedChartStatuses);
      setTpiPieChartData(mappedTpiValues);
      setOocPieChartData(Array.from(oocMap, ([name, value]) => ({ name, value })));
      setWarningPieChartData(Array.from(warningMap, ([name, value]) => ({ name, value })));
    }
  }, [rawSummaries]);

  const isNonUs = companyTypes && companyTypes[0] && companyTypes[0]['nonUs'];

  const controlStatusColumns = [
    { field: 'location', headerName: 'Location', flex: 3, minWidth: 200 },
    { field: 'methodAlias', headerName: 'Alias', flex: 1, minWidth: 100 },
    { field: 'method', headerName: 'Method', flex: 1, minWidth: 100 },
    { field: 'parameter', headerName: 'Parameter', flex: 2, minWidth: 150 },
    { field: 'flags', headerName: 'Flag', flex: 3, minWidth: 200 }
  ];

  const tpiColumns = [
    { headerName: 'Location', field: 'location', flex: 3, minWidth: 200 },
    { headerName: 'Alias', field: 'methodAlias', flex: 1, minWidth: 100 },
    { headerName: 'Method', field: 'method', flex: 1, minWidth: 100 },
    { headerName: 'Parameter', field: 'parameter', flex: 2, minWidth: 200 },
    {
      headerName: 'TPI',
      field: 'tpi_or',
      flex: 1,
      minWidth: 100,
      valueFormatter: (param) => {
        if (!Number(param.value)) return '';
        return param.value;
      }
    }
  ];

  const oocColumns = isNonUs
    ? [
        { headerName: 'Location', field: 'location', flex: 3, minWidth: 200 },
        { headerName: 'Alias', field: 'methodAlias', flex: 1, minWidth: 100 },
        { headerName: 'Instrument', field: 'instrument', flex: 1, minWidth: 100 },
        { headerName: 'Method', field: 'method', flex: 1, minWidth: 100 },
        { headerName: 'Parameter', field: 'parameter', flex: 2, minWidth: 200 },
        { headerName: 'Out of Control', field: 'ooc_or', flex: 1, minWidth: 200 }
      ]
    : [
        { headerName: 'Location', field: 'location', flex: 3, minWidth: 200 },
        { headerName: 'Alias', field: 'methodAlias', flex: 1, minWidth: 100 },
        { headerName: 'Method', field: 'method', flex: 1, minWidth: 100 },
        { headerName: 'Parameter', field: 'parameter', flex: 2, minWidth: 200 },
        { headerName: 'Out of Control', field: 'ooc_or', flex: 1, minWidth: 200 }
      ];

  const warningColumns = isNonUs
    ? [
        { headerName: 'Location', field: 'location', flex: 3, minWidth: 200 },
        { headerName: 'Alias', field: 'methodAlias', flex: 1, minWidth: 100 },
        { headerName: 'Instrument', field: 'instrument', flex: 1, minWidth: 100 },
        { headerName: 'Method', field: 'method', flex: 1, minWidth: 100 },
        { headerName: 'Parameter', field: 'parameter', flex: 2, minWidth: 200 },
        { headerName: 'Warning', field: 'warnings_or', flex: 1, minWidth: 200 }
      ]
    : [
        { headerName: 'Location', field: 'location', flex: 3, minWidth: 200 },
        { headerName: 'Alias', field: 'methodAlias', flex: 1, minWidth: 100 },
        { headerName: 'Method', field: 'method', flex: 1, minWidth: 100 },
        { headerName: 'Parameter', field: 'parameter', flex: 2, minWidth: 200 },
        { headerName: 'Warning', field: 'warnings_or', flex: 1, minWidth: 200 }
      ];

  const statusColumns = [
    {
      headerName: 'Location',
      field: 'location',
      flex: 3,
      align: 'center',
      headerAlign: 'center',
      minWidth: 200
    },
    {
      headerName: 'Alias',
      field: 'methodAlias',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      headerName: 'Method',
      field: 'method',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      headerName: 'Instrument',
      field: 'instrument',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      headerName: 'Sample Name',
      field: 'sampleName',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      headerName: 'Parameters',
      field: 'parameter',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      headerName: 'Chart stage',
      field: 'chartStage',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      headerName: 'Chart Status',
      field: 'chartStatus',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    }
  ];

  const detailsColumns = [
    { headerName: 'Location', field: 'location', flex: 3, minWidth: 250 },
    { headerName: 'Alias', field: 'methodAlias', flex: 1, minWidth: 80 },
    { headerName: 'Method', field: 'method', flex: 1, minWidth: 80 },
    { headerName: 'Instrument', field: 'instrument', flex: 1, minWidth: 100 },
    { headerName: 'Sample Name', field: 'sampleName', flex: 1.5, minWidth: 150 },
    { headerName: 'Parameters', field: 'parameter', flex: 1.5, minWidth: 150 },
    { headerName: 'Chart stage', field: 'chartStage', flex: 1.5, minWidth: 150 },
    { headerName: 'Chart status', field: 'chartStatus', flex: 1, minWidth: 80 },
    { headerName: 'Out of control', field: 'ooc_or', flex: 1, minWidth: 81 },
    { headerName: 'Warnings', field: 'warnings_or', flex: 1, minWidth: 100 },
    { headerName: 'Most recent sample', field: 'recent_date', flex: 2, minWidth: 150 },
    { headerName: 'Result', field: 'recent_sample', flex: 1, minWidth: 80 },
    { headerName: 'Average', field: 'avg_or', flex: 1, minWidth: 100 },
    { headerName: 'Site Precision', field: 'sp_or', flex: 1, minWidth: 100 },
    { headerName: 'TPI', field: 'tpi_or', flex: 1, minWidth: 50 }
  ];

  const COLORS = ['#FF2035', '#FF8042', '#FFBB28', '#00C49F', '#0088FE'];
  const statusColors = ['#45c425', '#FF2035'];

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  const kpiOptions = {
    // pageStartIndex: 0,
    sizePerPage: 5,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  const renderCellWithColor = (entry) => {
    const cellToReturn = [];
    if (entry.name === 'In control') {
      cellToReturn.push(<Cell fill={statusColors[0]} />);
    } else {
      cellToReturn.push(<Cell fill={statusColors[1]} />);
    }
    return cellToReturn;
  };

  return (
    <Section
      bg={bg}
      textColor={textColor}
      size="md"
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      {router.query.paid && auth.user.planIsActive && (
        <Alert variant="success" className="text-center mx-auto mb-5" style={{ maxWidth: '400px' }}>
          You are now subscribed to the {auth.user.planId} plan
          <span className="ml-2" role="img" aria-label="party">
            🥳
          </span>
        </Alert>
      )}

      <Row>
        <Col lg={12}>
          <Tabs defaultActiveKey="kpis" id="uncontrolled-tab-example" className="tri-tabs">
            <Tab eventKey="kpis" title="KPIs">
              <div className="full-kpi-container">
                <div className="left-kpi-container">
                  <h3 className="kpi-header">Key Performance Indices - Control Status</h3>
                  <Card className="card-class">
                    <Card.Body>
                      <div className="chart-title">
                        <h3>Control Status</h3>
                      </div>
                      <div className="chart-container">
                        <PieChart width={330} height={320}>
                          <Pie
                            data={statusPieChartData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={130}
                            fill="#8884d8"
                          >
                            {statusPieChartData.map((entry) => renderCellWithColor(entry))}
                          </Pie>
                          <Legend verticalAlign="bottom" height={36} />
                        </PieChart>
                      </div>
                    </Card.Body>
                  </Card>
                  <h3 className="kpi-header">
                    Key Performance Indices - TPI by method and parameter
                  </h3>
                  <Card className="card-class">
                    <Card.Body>
                      <div className="chart-title">
                        <h3>Test Performance Index</h3>
                      </div>
                      <div className="chart-container">
                        <PieChart width={330} height={320}>
                          <Pie
                            data={tpiPieChartData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={130}
                            fill="#8884d8"
                          >
                            {tpiPieChartData.map((entry, index) => (
                              <Cell fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Legend verticalAlign="bottom" height={36} />
                        </PieChart>
                      </div>
                    </Card.Body>
                  </Card>
                  <h3 className="kpi-header">
                    Out of control data by {isNonUs ? 'Instrument' : 'Method'} (
                    {oocPieChartData.length})
                  </h3>
                  <Card className="card-class">
                    <Card.Body>
                      <div className="chart-title">
                        <h3>Out of Control Index</h3>
                      </div>
                      <div className="chart-container">
                        {isNonUs ? (
                          <SQCBarChart data={oocPieChartData} />
                        ) : (
                          <SQCPieChart data={oocPieChartData} />
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                  <h3 className="kpi-header">
                    Warning data by {isNonUs ? 'Instrument' : 'Method'} (
                    {warningPieChartData.length})
                  </h3>
                  <Card className="card-class">
                    <Card.Body>
                      <div className="chart-title">
                        <h3>Warning Index</h3>
                      </div>
                      <div className="chart-container">
                        {isNonUs ? (
                          <SQCBarChart data={warningPieChartData} />
                        ) : (
                          <SQCPieChart data={warningPieChartData} />
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="right-kpi-container">
                  <div className="kpi-table-container">
                    <div className="kpi-select">
                      <Form.Group as={Col} controlId="location">
                        <Form.Select
                          value={currentLocation}
                          onChange={handleLocationChange}
                          aria-label="Default select example"
                        >
                          <option value="">Select location</option>
                          {renderOptionsDynamic(allLocations)}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div style={{ width: '100%' }}>
                      <CustomTable
                        header={controlStatusColumns}
                        data={currentSummaries}
                        numberOfRows={5}
                      />
                    </div>
                  </div>
                  <div className="kpi-table-container">
                    <div className="kpi-select">
                      <Form.Group as={Col} controlId="location-two">
                        <Form.Select
                          value={currentLocation}
                          onChange={handleLocationChangeTpi}
                          aria-label="Default select example"
                        >
                          <option value="">Select location</option>
                          {renderOptionsDynamic(allLocations)}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div>
                      <CustomTable
                        numberOfRows={5}
                        header={tpiColumns}
                        data={currentSummariesTpi}
                      />
                    </div>
                  </div>
                  <div className="kpi-ooc-warning-table-container">
                    <div className="kpi-select">
                      <Form.Group as={Col} controlId="location-two">
                        <Form.Select
                          value={currentLocation}
                          onChange={handleLocationOOCChange}
                          aria-label="Default select example"
                        >
                          <option value="">Select location</option>
                          {renderOptionsDynamic(allLocations)}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div>
                      <CustomTable
                        numberOfRows={5}
                        header={oocColumns}
                        data={currentSummariesOOC}
                      />
                    </div>
                  </div>
                  <div className="kpi-ooc-warning-table-container">
                    <div className="kpi-select">
                      <Form.Group as={Col} controlId="location-two">
                        <Form.Select
                          value={currentLocation}
                          onChange={handleLocationWarningChange}
                          aria-label="Default select example"
                        >
                          <option value="">Select location</option>
                          {renderOptionsDynamic(allLocations)}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div>
                      <CustomTable
                        numberOfRows={5}
                        header={warningColumns}
                        data={currentSummariesWarning}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="status" title="Status">
              <div className="status-container">
                <div className="status-header">
                  <h2>Status Dashboard</h2>
                  <div className="underline-header">
                    <hr />
                  </div>
                </div>
                <div className="status-table">
                  <CustomTable header={statusColumns} data={currentSummaries} numberOfRows={10} />
                </div>
              </div>
            </Tab>
            <Tab eventKey="details" title="Details">
              <div className="chart-details-container">
                <div className="chart-details-header">
                  <div className="header">
                    <h2>Chart Details</h2>
                  </div>
                  <div className="underline-header">
                    <hr />
                  </div>
                </div>
                <div className="chart-details-table">
                  <CustomTable header={detailsColumns} data={currentSummaries} numberOfRows={10} />
                </div>
                <div className="flag-definitions">
                  <h3>Flag Definitions</h3>
                  <Card className="flag-definitions-card">
                    <Card.Body>
                      <div className="flag-definitions-container">
                        <div className="left-column">
                          <div className="flag-row">
                            <div className="flag">UCL</div>
                            <div className="description">
                              Individual result above Upper Control Limit
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">LCL</div>
                            <div className="description">
                              Individual result below Lower Control Limit
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              RC
                              {'>'}
                              2&sigma;
                            </div>
                            <div className="description">
                              Recheck result above 2&sigma; from center line
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              RC
                              {'<'}
                              2&sigma;
                            </div>
                            <div className="description">
                              Recheck result below 2&sigma; from center line
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              2/3
                              {'>'}
                              2&sigma;
                            </div>
                            <div className="description">
                              2/3 of results {'>'} 2&sigma; from center line
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              2/3
                              {'<'}
                              2&sigma;
                            </div>
                            <div className="description">
                              2/3 of results {'<'} 2&sigma; from center line
                            </div>
                          </div>
                        </div>
                        <div className="center-column">
                          <div className="flag-row">
                            <div className="flag">
                              5CP
                              {'>'}
                              1&sigma;
                            </div>
                            <div className="description">
                              5 consecutive points {'>'} 1&sigma; from the center line
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              5CP
                              {'<'}
                              1&sigma;
                            </div>
                            <div className="description">
                              5 consecutive points {'<'} 1&sigma; from the center line
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">7CP-I</div>
                            <div className="description">7 consecutive points increasing</div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">7CP-D</div>
                            <div className="description">7 consecutive points decreasing</div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              9CP
                              {'>'}
                              CL
                            </div>
                            <div className="description">
                              9 consecutive points above the center line
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              9CP
                              {'<'}
                              CL
                            </div>
                            <div className="description">
                              9 consecutive points below the center line
                            </div>
                          </div>
                        </div>
                        <div className="right-column">
                          <div className="flag-row">
                            <div className="flag">E-UCL</div>
                            <div className="description">EWMA trend line above the trend UCL</div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">E-LCL</div>
                            <div className="description">EWMA trend line below the trend UCL</div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              5/20
                              {'>'}
                              MR
                            </div>
                            <div className="description">
                              5 of 20 points above the Moving Range UCL
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">
                              1{'>'}
                              MR
                            </div>
                            <div className="description">
                              Single point above the Moving Range UCL
                            </div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">GESD</div>
                            <div className="description">GESD Outlier in the setup data</div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">ALT</div>
                            <div className="description">Alt chart control limits exceeded</div>
                          </div>
                          <div className="flag-row">
                            <div className="flag">TOL</div>
                            <div className="description">Tolerance limits exceeded</div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Section>
  );
}

DashboardSection.propTypes = {
  bg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired
};

export default DashboardSection;
